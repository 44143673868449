import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {Datatable} from './components/datatable.component';
import {datetime_set} from './plugins/datetime';
import {DatatableSearch} from './components/datatable-search.component';
import {DatatableService} from './datatable.service';

// DataTable varsayılan hata yakalamayı kapat
// $.fn.dataTable.ext.errMode = 'none';
datetime_set();

$.fn.dataTable['moment']('YYYY-MM-DD HH:mm:ss');
$.fn.dataTable['moment']('dddd, MMMM Do, YYYY');

const MODULES = [
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
];

const COMPONENTS = [
    Datatable,
    DatatableSearch,
];

const PIPES = [];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
})
export class DatatableModule
{
    static forRoot(): ModuleWithProviders<DatatableModule>
    {
        return {
            ngModule: DatatableModule,
            providers: [DatatableService],
        };
    }
}
