import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {Observable, of as observableOf} from 'rxjs';
import {switchMap, map, catchError} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {AuthProvider} from '../auth-provider';
import {AuthProviderClass} from '../../auth-options';
import {PasswordAuthStrategyOptions, passwordProviderOptions} from './password-provider-options';
import {AuthResult} from '../../services/auth-result';
import {AuthIllegalTokenError} from '../../exceptions/token/auth-illegal-token';

@Injectable()
export class PasswordAuthProvider extends AuthProvider
{
    /**
     * Oturum sağlayıcı adı.
     *
     * @type {string}
     * @protected
     */
    protected name = 'email';

    protected baseEndpoint = `${environment.api_endpoint}/auth/`;

    protected defaultOptions: PasswordAuthStrategyOptions = passwordProviderOptions;

    protected requireValidToken = true;

    protected alwaysFail: boolean;

    protected redirect = '/pages/dashboard';

    constructor(protected http: HttpClient, private route: ActivatedRoute)
    {
        super();
    }

    /**
     *
     * @param data
     *
     * @return {Observable<AuthResult>}
     */
    authenticate(data?: any): Observable<AuthResult>
    {
        const module = 'token';
        const url = this.getActionEndpoint(module);

        return this.http.request('post', url, {body: data, observe: 'response'})
            .pipe(
                map((response) => {
                    if (this.alwaysFail) {
                        throw this.createFailResponse(data);
                    }
                    return response;
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        this.redirect,
                        [],
                        (response.body as any).message,
                        this.createToken(this.getOption('token.getter')(module, response, this.options), this.requireValidToken));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    register(data?: any): Observable<AuthResult>
    {
        const module = 'register';
        const method = this.getOption(`${module}.method`);
        const url = this.getActionEndpoint(module);
        const requireValidToken = this.getOption(`${module}.requireValidToken`);
        return this.http.request(method, url, {body: data, observe: 'response'})
            .pipe(
                map((res) => {
                    if (this.getOption(`${module}.alwaysFail`)) {
                        throw this.createFailResponse(data);
                    }

                    return res;
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        this.getOption(`${module}.redirect.success`),
                        [],
                        this.getOption('messages.getter')(module, response, this.options),
                        this.createToken(this.getOption('token.getter')('login', response, this.options), requireValidToken));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    requestPassword(data?: any): Observable<AuthResult>
    {
        const module = 'requestPass';
        const method = this.getOption(`${module}.method`);
        const url = this.getActionEndpoint(module);
        return this.http.request(method, url, {body: data, observe: 'response'})
            .pipe(
                map((res) => {
                    if (this.getOption(`${module}.alwaysFail`)) {
                        throw this.createFailResponse();
                    }

                    return res;
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        this.getOption(`${module}.redirect.success`),
                        [],
                        this.getOption('messages.getter')(module, response, this.options));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    resetPassword(data: any = {}): Observable<AuthResult>
    {
        const module = 'resetPass';
        const method = this.getOption(`${module}.method`);
        const url = this.getActionEndpoint(module);
        const tokenKey = this.getOption(`${module}.resetPasswordTokenKey`);
        data[tokenKey] = this.route.snapshot.queryParams[tokenKey];
        return this.http.request(method, url, {body: data, observe: 'response'})
            .pipe(
                map((response) => {
                    if (this.getOption(`${module}.alwaysFail`)) {
                        throw this.createFailResponse();
                    }

                    return response;
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        this.getOption(`${module}.redirect.success`),
                        [],
                        this.getOption('messages.getter')(module, response, this.options));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    logout(): Observable<AuthResult>
    {
        const module = 'revoke';
        const url = this.getActionEndpoint(module);

        return observableOf({})
            .pipe(
                switchMap((response: any) => {
                    if (!url) {
                        return observableOf(response);
                    }
                    return this.http.request('delete', url, {observe: 'response'});
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        '/auth/login',
                        [],
                        this.getOption('messages.getter')(module, response, this.options));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    refreshToken(data?: any): Observable<AuthResult>
    {
        const module = 'refreshToken';
        const method = this.getOption(`${module}.method`);
        const url = this.getActionEndpoint(module);
        const requireValidToken = this.getOption(`${module}.requireValidToken`);

        return this.http.request(method, url, {body: data, observe: 'response'})
            .pipe(
                map((response) => {
                    if (this.getOption(`${module}.alwaysFail`)) {
                        throw this.createFailResponse(data);
                    }

                    return response;
                }),
                map((response) => {
                    return new AuthResult(
                        true,
                        response,
                        this.getOption(`${module}.redirect.success`),
                        [],
                        this.getOption('messages.getter')(module, response, this.options),
                        this.createToken(this.getOption('token.getter')(module, response, this.options), requireValidToken));
                }),
                catchError((res) => {
                    return this.handleResponseError(res, module);
                }),
            );
    }

    /**
     * Oturum açmış kullanıcının parolasını doğrulayın.
     *
     * @param {string} password
     *
     * @returns {Observable<AuthResult>}
     */
    validatePassword(password?: string): Observable<AuthResult>
    {
        const module = 'ValidatePassword';
        const url = this.getActionEndpoint('validate-password');

        return this.http.request('post', url, {body: {password}, observe: 'response'})
            .pipe(
                map(response => {
                    return new AuthResult(true, response, null, [], 'Parola doğrulandı.');
                }),
                catchError(response => this.handleResponseError(response, module)),
            );
    }

    protected handleResponseError(response: any, module: string): Observable<AuthResult>
    {
        const errors = [];
        let message = '';

        if (response instanceof HttpErrorResponse) {
            if (response.error.hasOwnProperty('errors')) {
                for (const [key, value] of Object.entries(response.error.errors)) {
                    errors.push(value);
                }
            }
            else {
                message = response.error.message;
            }
        }
        else if (response instanceof AuthIllegalTokenError) {
            errors.push(response.message);
        }
        else {
            errors.push('Something went wrong.');
        }

        return observableOf(new AuthResult(false, response, this.getOption(`${module}.redirect.failure`), errors, message));
    }

    static setup(options: PasswordAuthStrategyOptions): [AuthProviderClass, PasswordAuthStrategyOptions]
    {
        return [PasswordAuthProvider, options];
    }
}
