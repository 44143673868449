import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhonePipe} from './phone.pipe';

@NgModule({
    imports     : [CommonModule],
    exports     : [PhonePipe],
    declarations: [PhonePipe],
    providers   : [PhonePipe]
})
export class PhonePipeModule
{
}
