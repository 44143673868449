import {InjectionToken} from '@angular/core';
import {AuthProvider} from './strategies/auth-provider';
import {AuthProviderOptions} from './strategies/auth-provider-options';
import {AuthTokenClass} from './services/token/auth-token/auth-token.interface';
import {AuthToken} from './services/token/auth-token/auth-token';

export type AuthProviderClass = new (...params: any[]) => AuthProvider;
export type AuthProviders = [AuthProviderClass, AuthProviderOptions][];


export interface AuthOptions
{
    forms?: any;
    strategies?: AuthProviders;
}


export const AUTH_OPTIONS = new InjectionToken<AuthOptions>('Auth Options');
export const AUTH_USER_OPTIONS = new InjectionToken<AuthOptions>('User Auth Options');
export const AUTH_PROVIDERS = new InjectionToken<AuthProviders>('Auth Providers');
export const AUTH_TOKENS = new InjectionToken<AuthTokenClass<AuthToken>[]>('Auth Tokens');
