export function labelForAvatar(name: string, lastname: string)
{
    if (!name) {
        return '?';
    }
    if (name && lastname) {
        return `${name.charAt(0)}${lastname.charAt(0)}`;
    }

    return '?';
}
