import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {RippleModule} from 'primeng/ripple';
import {SpeedDialModule} from 'primeng/speeddial';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {HttpInterceptorProviders} from '../../interceptors';
import {LayoutRoutingModule} from './layout-routing.module';
import {LayoutService} from './services/layout.service';
import {FooterComponent} from './views/footer.component';
import {HeaderComponent} from './views/header.component';
import {PageComponent} from './views/page.component';

const MODULES = [
    LayoutRoutingModule,
    ToastModule,
    SpeedDialModule,
    TooltipModule,
    ConfirmDialogModule,
    MenubarModule,
    MenuModule,
    RippleModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
];

const COMPONENTS = [
    PageComponent,
    HeaderComponent,
    FooterComponent,
];

const PROVIDERS = [
    LayoutService,
];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...MODULES],
    declarations: [...COMPONENTS],
    providers: [HttpInterceptorProviders, ...PROVIDERS]
})
export class LayoutModule
{
}
