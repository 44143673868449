<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <p-menubar [model]="authenticatedUser?.role === 'admin' ? menuItems : []" class="w-100">
            <ng-template pTemplate="start">
                <a *ngIf="authenticatedUser?.role === 'admin'" routerLink="/pages/dashboard" class="navbar-brand">
                    <img src="../../../../../assets/img/pam-logo.png" alt="PAM" class="img-fluid"/>
                </a>
                <a *ngIf="authenticatedUser?.role !== 'admin'" href="javascript:void(0)" class="navbar-brand">
                    <img src="../../../../../assets/img/pam-logo.png" alt="PAM" class="img-fluid"/>
                </a>
            </ng-template>
            <ng-template pTemplate="end">
                <p-menubar [model]="rightMenuItems"></p-menubar>
            </ng-template>
        </p-menubar>
    </div>
</nav>
