import {AuthTokenClass} from '../services/token/auth-token/auth-token.interface';

export interface DriverToken
{
    class?: AuthTokenClass;
    [key: string]: any;
}

export class AuthProviderOptions
{
    name: string;
    token?: DriverToken;
}
