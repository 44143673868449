import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {QueryStringParameters, UrlBuilder} from '../classes';

@Injectable()
export class ApiEndpointsService
{
    public createUrl(action: string, isPublic = false): string
    {
        const urlBuilder: UrlBuilder = new UrlBuilder(isPublic ? environment.public_api_endpoint : environment.api_endpoint, action);

        return urlBuilder.toString();
    }

    public createUrlWithQueryParameters(action: string, queryStringHandler?: (queryStringParameters: QueryStringParameters) => void, isPublic = false): string
    {
        const urlBuilder: UrlBuilder = new UrlBuilder(isPublic ? environment.public_api_endpoint : environment.api_endpoint, action);

        if (queryStringHandler) {
            queryStringHandler(urlBuilder.queryString);
        }

        return urlBuilder.toString();
    }

    public createUrlWithPathVariables(action: string, pathVariables: any[] = [], isPublic = false): string
    {
        let encodedPathVariablesUrl: string = '';

        for (const pathVariable of pathVariables) {
            if (pathVariable !== null) {
                encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
            }
        }
        const urlBuilder: UrlBuilder = new UrlBuilder(isPublic ? environment.public_api_endpoint : environment.api_endpoint, `${action}${encodedPathVariablesUrl}`);

        return urlBuilder.toString();
    }
}
