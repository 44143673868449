import {AuthToken} from './token/auth-token/auth-token';

export class AuthResult
{
    protected user: any = {};
    protected token: AuthToken;
    protected errors: string[] = [];
    protected message: string;

    constructor(protected success: boolean, protected response?: any, protected redirect?: any, errors?: any, message?: any, token: AuthToken = null)
    {
        this.errors = this.errors.concat([errors]);
        if (errors instanceof Array) {
            this.errors = errors;
        }

        this.message = message;
        this.user = response.body?.user_data ? JSON.stringify(response.body.user_data) : null;
        this.token = token;
    }

    getResponse(): any
    {
        return this.response;
    }

    getToken(): AuthToken
    {
        return this.token;
    }

    getUser()
    {
        return this.user;
    }

    /**
     * Kullanıcının jetonu aldıktan sonra yönlendirileceği URL'i alın.
     *
     * @returns {string}
     */
    getRedirect(): string
    {
        return this.redirect;
    }

    /**
     * Validasyon hatalarının listesini alın.
     *
     * @returns {string[]}
     */
    getErrors(): string[]
    {
        return this.errors.filter(val => !!val);
    }

    getMessage(): string
    {
        return this.message;
    }

    /**
     * Jeton alma isteğinin başarılı olup olmadığını kontrol edin.
     *
     * @returns {boolean}
     */
    isSuccess(): boolean
    {
        return this.success;
    }

    /**
     * Jeton alma isteğinin başarısız olup olmadığını kontrol edin.
     *
     * @returns {boolean}
     */
    isFailure(): boolean
    {
        return !this.success;
    }
}
