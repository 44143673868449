<div [formGroup]="form">

    <div class="row mb-3">
        <div class="col-sm-12">
            <p-messages key="messages.dialog" [escape]="false"></p-messages>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-sm-12">
            <div class="alert alert-primary" role="alert">
                {{ driverFullname }} isimli sürücüye SMS doğrulama kodu ulaşmamışsa, bu bölümden doğrulama kodu oluşturabilir ve yeniden gönderim işlemi yapabilirsiniz.<br/><br/>
                SMS gönderiminde sorun yaşanıyorsa aşağıda oluşturulacak doğrulama kodunu, sürücüye bildirmeniz gerekir.
            </div>
        </div>
    </div>

    <div *ngIf="response && response.success" class="row mb-3">
        <div class="col-sm-12">
            <label class="mb-3">Doğrulama Kodu</label>
            <p class="fs-2 text-center font-monospace" style="letter-spacing: 16px">{{ response?.verification_code || '-' }}</p>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-sm-12">
            <div class="p-field-checkbox">
                <p-checkbox formControlName="send_as_sms" [binary]="true" inputId="sendAsSms"></p-checkbox>
                <label for="sendAsSms">Doğrulama kodunu SMS olarak gönder</label>
            </div>
        </div>
    </div>

</div>

<div style="display:flex;gap:15px;justify-content:center;">
    <button pButton pRipple type="button" class="p-button-outlined" label="Vazgeç" (click)="onCanceled()"></button>
    <button pButton pRipple type="button" label="Doğrulama Kodu Oluştur" [loading]="loading" (click)="onSubmit()"></button>
</div>
