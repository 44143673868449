<div class="mb-3" pFocusTrap>
    <textarea rows="5" autofocus cols="30" pInputTextarea [formControl]="controlReason"></textarea>
    <ng-container *ngIf="(controlReason.touched || controlReason.dirty) && controlReason.errors">
        <div class="invalid-message" *ngIf="controlReason.errors.maxlength">Açıklama alanı için maksimum karakter sınırını geçtiniz</div>
    </ng-container>
</div>

<div style="display:flex;gap:15px;justify-content:end;">
    <button pButton pRipple type="button" class="p-button-outlined" label="Vazgeç" icon="pi pi-times" (click)="onCanceled()"></button>
    <button pButton pRipple type="button" label="Kaydet" icon="pi pi-check" (click)="onSubmit()"></button>
</div>
