import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor
{
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const headers: any = {};

        if (!request.headers.has('Accept')) {
            headers['Accept'] = 'application/json';
        }

        headers['X-Client-Language'] = 'tr';
        headers['X-Client-Version'] = environment.app_version;

        const req = request.clone({setHeaders: headers});

        return next.handle(req)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }
}
