import {AfterViewInit, Component, EmbeddedViewRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
    selector: 'page-actions',
    template: `
        <ng-template #pageActions>
            <ng-content></ng-content>
        </ng-template>`,
})
export class PageActionsComponent implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('pageActions')
    public portalActionsTmplRef;

    private disposeFn: () => void;

    private viewRef: EmbeddedViewRef<{}>;

    constructor(private viewContainerRef: ViewContainerRef)
    {
    }

    ngOnInit()
    {
    }

    ngAfterViewInit(): void
    {
        // render the view
        this.viewRef = this.viewContainerRef.createEmbeddedView(
            this.portalActionsTmplRef
        );

        this.viewRef.detectChanges();

        // DOM öğesini yakala
        const outletElement = document.querySelector('#page-actions-container');

        // görünümü seçicinizle eşleşen DOM öğesine ekleyin
        this.viewRef.rootNodes.forEach(rootNode =>
            outletElement.appendChild(rootNode)
        );

        // daha sonra arayabileceğimiz bir imha fn kaydedin
        // içeriği tekrar DOM'dan kaldırmak için
        this.disposeFn = () => {
        };
    }

    ngOnDestroy(): void
    {
        const index = this.viewContainerRef.indexOf(this.viewRef);
        if (index !== -1) {
            this.viewContainerRef.remove(index);
        }
    }
}
