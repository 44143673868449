import {Injectable} from '@angular/core';
import {AuthTokenParceler} from './token-parceler';
import {AuthToken} from './auth-token/auth-token';

export abstract class TokenStorage
{
    abstract get(): AuthToken;

    abstract set(token: AuthToken);

    abstract clear();
}

/**
 * Service that uses browser localStorage as a storage.
 *
 * The token storage is provided into auth module the following way:
 * ```ts
 * { provide: TokenStorage, useClass: TokenLocalStorage },
 * ```
 *
 * If you need to change the storage behaviour or provide your own - just extend your class from basic `NbTokenStorage`
 * or `TokenLocalStorage` and provide in your `app.module`:
 * ```ts
 * { provide: TokenStorage, useClass: TokenCustomStorage },
 * ```
 *
 */
@Injectable()
export class TokenLocalStorage extends TokenStorage
{
    protected key = 'auth_app_token';

    constructor(private parceler: AuthTokenParceler)
    {
        super();
    }

    /**
     * Yerel depolamadan jetonu alın.
     *
     * @returns {AuthToken}
     */
    get(): AuthToken
    {
        const raw = localStorage.getItem(this.key);

        return this.parceler.unwrap(raw);
    }

    /**
     * Yerel depolamaya jetonu yerleştirin.
     *
     * @param {AuthToken} token
     *
     * @return {void}
     */
    set(token: AuthToken): void
    {
        const raw = this.parceler.wrap(token);

        localStorage.setItem(this.key, raw);
    }

    /**
     * Yerel depolamadan jetonu kaldırın.
     *
     * @return {void}
     */
    clear(): void
    {
        localStorage.removeItem(this.key);
        localStorage.removeItem('user_data');
    }
}
