import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MomentPipe} from './moment.pipe';

@NgModule({
    imports     : [CommonModule],
    exports     : [MomentPipe],
    declarations: [MomentPipe],
    providers   : [MomentPipe]
})
export class MomentModule
{
}
