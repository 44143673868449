import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
    constructor(private authService: AuthService, private router: Router)
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        // Giriş yapılmadıysa, mevcut URL ile birlikte giriş sayfasına yönlendir
                        this.router.navigate(['/auth/login'], {queryParams: {redirect: state.url}});
                    }
                }),
            );
    }
}
