import {Injectable} from '@angular/core';

@Injectable()
export class DatatableService
{
    /**
     * Datatable yapılandırması.
     *
     * @type {Object}
     */
    private _config = {};

    /**
     * Datatable yapılandırmasını ayarlayın.
     *
     * @param config
     *
     * @return {this}
     */
    public setConfig(config)
    {
        this._config = config;

        return this;
    }

    /**
     * Datatable yapılandırmasını alın.
     *
     * @return {Object}
     */
    public getConfig()
    {
        return this._config;
    }

    public get(key: string): any
    {
        return this._config[key];
    }
}

