import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {AppInjector} from './app/core/classes/app-injector';
import {environment} from './environments/environment';
import drawLocales from 'leaflet-draw-locales';
import * as moment from 'moment/moment';
import 'moment/locale/tr';

if (environment.production) {
    enableProdMode();
}

moment.locale('tr');
drawLocales('tr');

platformBrowserDynamic().bootstrapModule(AppModule)
    .then((moduleRef) => AppInjector.setInjector(moduleRef.injector))
    .catch(err => console.error(err));
