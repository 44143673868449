import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform
{
    transform(date: any, format: string | boolean = 'full'): string
    {
        const _moment = moment(date);

        if (!_moment.isValid()) {
            return date;
        }

        if (typeof format === 'boolean' && format === true) {
            return _moment.fromNow();
        }
        else {
            switch (format) {
                case 'short':
                    return _moment.format('DD.MM.YYYY');
                case 'time':
                    return _moment.format('HH:mm');
                case 'full':
                    return _moment.format('DD.MM.YYYY HH:mm');
                default:
                    return _moment.format(format as string);
            }
        }
    }
}
