import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {takeUntil} from 'rxjs/operators';
import {ComponentBase} from '../../../classes/component-base';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'app-header',
    }
})
export class HeaderComponent extends ComponentBase implements OnInit, OnDestroy
{
    public menuItems: MenuItem[] = [
        {label: 'Özet', routerLink: '/pages/dashboard'},
        {
            label: 'Sürücüler',
            items: [
                {label: 'Tüm Sürücüler', routerLink: '/pages/drivers/listing/all'},
                {label: 'Çevrimiçi Sürücüler', routerLink: '/pages/drivers/listing/online'},
                {label: 'Aktif Sürücüler', routerLink: '/pages/drivers/listing/active'},
                {label: 'Askıya Alınanlar', routerLink: '/pages/drivers/listing/suspended'},
                {label: 'Belgesi Onay Bekleyenler', routerLink: '/pages/drivers/listing/pending-documents'},
                {label: 'Bakiyesi Bitenler', routerLink: '/pages/drivers/listing/insufficient-balance'},
            ]
        },
        {
            label: 'Talepler',
            items: [
                {label: 'Tüm Talepler', routerLink: '/pages/requests/listing/all'},
                {label: 'Tamamlanan Talepler', routerLink: '/pages/requests/listing/finished'},
                {label: 'Sürücü İptalleri', routerLink: '/pages/requests/listing/driver-canceled'},
                {label: 'Müşteri İptalleri', routerLink: '/pages/requests/listing/customer-canceled'},
                {label: 'Süresi Geçmiş Talepler', routerLink: '/pages/requests/listing/expired'},
            ]
        },
        {
            label: 'Müşteriler',
            items: [
                {label: 'Tüm Müşteriler', routerLink: '/pages/customers/listing/all'},
                {label: 'Aktif Müşteriler', routerLink: '/pages/customers/listing/active'},
                {label: 'Askıya Alınmış', routerLink: '/pages/customers/listing/suspended'},
            ]
        },
        // {label: 'Hakedişler', routerLink:'/pages/payments/listing/all'},
        {
            label: 'Ayarlar',
            items: [
                {label: 'Bölgeler', routerLink: '/pages/settings/regions'},
                {label: 'Hizmet Kategorileri', routerLink: '/pages/settings/service-categories'},
                {label: 'Hizmetler', routerLink: '/pages/settings/services'},
                {label: 'Talep Tercihleri', routerLink: '/pages/settings/request'},
            ]
        },
    ];

    public rightMenuItems: MenuItem[] = [];

    public authenticatedUser;

    constructor(private router: Router)
    {
        super();
        this.$authService.isAuthenticated().pipe(takeUntil(this.destroy$)).subscribe(isAuthenticated => {
            if (!isAuthenticated) {
                return;
            }
            this.authenticatedUser = this.$authService.getUser();

            this.rightMenuItems = [
                {
                    label: this.authenticatedUser.name,
                    icon: 'pi pi-user',
                    items: [
                        {label: 'Oturumu Kapat', command: () => this.onLogout()},
                    ]
                },
            ];

            if (this.authenticatedUser.role === 'admin') {
                this.rightMenuItems.unshift({
                    label: 'Yeni',
                    icon: 'pi pi-plus',
                    items: [
                        {label: 'Sürücü Oluştur', routerLink: '/pages/drivers/create'},
                        {label: 'Müşteri Oluştur', routerLink: '/pages/customers/create'},
                        {label: 'Anlık Bildirim', routerLink: '/pages/notifications/create'},
                    ]
                });
            }
        });
    }

    onLogout(): void
    {
        this.$authService.logout().pipe(takeUntil(this.destroy$)).subscribe(result => {
            this.router.navigate([result.getRedirect()]);
        });
    }

    ngOnInit(): void
    {
    }

    ngOnDestroy(): void
    {
        super.ngOnDestroy();
        this.menuItems = null;
    }
}
