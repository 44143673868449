import {APP_BASE_HREF, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import localeTr from '@angular/common/locales/tr';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {DatatableModule} from './shared/components/datatable';
import {SharedModule} from './shared/shared.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';

registerLocaleData(localeTr, 'tr');

const MODULES = [
    AppRoutingModule,
    CoreModule.forRoot(),
    LeafletModule,
    SharedModule.forRoot(),
    DatatableModule.forRoot(),
];

const PROVIDERS = [
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: LOCALE_ID, useValue: 'tr-TR'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'TRY'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
];

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, ...MODULES],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: PROVIDERS,
})
export class AppModule
{
}
