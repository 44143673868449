import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {RippleModule} from 'primeng/ripple';
import {SendVerificationCodeDialogComponent} from './components/send-verification-code-dialog.component';
import {VerificationCodeService} from './services/verification-code.service';

const MODULES = [
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    RippleModule,
    ButtonModule,
    MessagesModule,
    CheckboxModule,
];

const COMPONENTS = [
    SendVerificationCodeDialogComponent,
];

const PIPES = [];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
    providers: [VerificationCodeService],
})
export class SendVerificationDialogModule
{
}
