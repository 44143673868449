import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/auth/lib/guards/auth.guard';
import {AutoLoginGuard} from './core/auth/lib/guards/auto-login.guard';

const ROUTES: Routes = [
    {path: 'pages', loadChildren: async () => (await import('./core/layout/lib/layout.module')).LayoutModule, canActivate: [AuthGuard]},
    {path: 'auth', loadChildren: () => import('./core/auth').then(m => m.AuthModule), canLoad: [AutoLoginGuard]},
    {path: '', redirectTo: 'auth/login', pathMatch: 'full'},
    {path: '**', redirectTo: 'auth/login', pathMatch: 'full'}
];

const ROUTE_CONFIG: ExtraOptions = {
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, ROUTE_CONFIG)],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
