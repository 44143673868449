<div class="login-wrapper">

    <div class="login-content">
        <main class="form-signin">

            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">

                <h3 class="mb-4">Giriş yapın</h3>

                <div class="alert alert-danger" role="alert" *ngIf="isError">
                    <div>{{ authResult.getMessage() }}</div>
                    <ul class="m-0" *ngIf="authResult.getErrors()">
                        <li *ngFor="let error of authResult.getErrors()">{{ error }}</li>
                    </ul>
                </div>

                <p-messages key="messages" [escape]="false"></p-messages>

                <div class="row mb-3">
                    <div class="col-12">
                        <input type="text" pInputText formControlName="email" placeholder="E-posta adresi"/>
                        <ng-container *ngIf="formSubmitted && (controlEmail.touched || controlEmail.dirty) && controlEmail.errors">
                            <div class="invalid-message" *ngIf="controlEmail.errors.required">E-posta gerekli</div>
                            <div class="invalid-message" *ngIf="controlEmail.errors.email || controlEmail.errors.minlength || controlEmail.errors.minlength">E-posta adresi geçersiz</div>
                        </ng-container>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <p-password formControlName="password" placeholder="Parola" [feedback]="false"></p-password>
                        <ng-container *ngIf="formSubmitted && (controlPassword.touched || controlPassword.dirty) && controlPassword.errors">
                            <div class="invalid-message" *ngIf="controlPassword.errors.required">Parola gerekli</div>
                            <div class="invalid-message" *ngIf="controlPassword.errors.minlength || controlPassword.errors.minlength">Parola geçersiz</div>
                        </ng-container>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p-button label="Gönder" [loading]="loading" type="submit"></p-button>
                    </div>
                </div>
            </form>
        </main>
    </div>

</div>

<footer class="auth-footer">
    <div class="auth-footer-wrapper">
        <div></div>
        <div>&copy; 2023, <a href="https://larvey.com?utm_source=pam&utm_medium=login&utm_campaign=pamplatformapp.com" target="_blank" title="Larvey Yazılım Teknolojileri">Larvey Yazılım Teknolojileri</a></div>
    </div>
</footer>
