import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ComponentBase} from '../../../../../core/classes/component-base';

@Component({
    selector: 'input-dialog',
    templateUrl: './input-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InputDialogComponent extends ComponentBase implements OnInit, OnDestroy
{
    public controlReason = new FormControl('', Validators.maxLength(1000));

    loading: boolean;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig)
    {
        super();
    }

    onSubmit(): void
    {
        this.ref.close(this.controlReason.value);
    }

    onCanceled(): void
    {
        this.ref.close(false);
    }

    ngOnInit(): void
    {
    }

    ngOnDestroy(): void
    {
    }
}
