import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'app-footer',
    }
})
export class FooterComponent implements OnInit, OnDestroy
{
    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    ngOnDestroy(): void
    {
    }
}
