import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PageComponent} from './views/page.component';

const routes: Routes = [{
    path: '',
    component: PageComponent,
    children: [
        {path: 'dashboard', loadChildren: async () => (await import('../../../modules/dashboard')).DashboardModule, canLoad: []},
        {path: 'drivers', loadChildren: async () => (await import('../../../modules/drivers')).DriversModule},
        {path: 'customers', loadChildren: async () => (await import('../../../modules/customers')).CustomersModule},
        {path: 'requests', loadChildren: async () => (await import('../../../modules/requests')).RequestsModule},
        {path: 'payments', loadChildren: async () => (await import('../../../modules/payments')).PaymentsModule},
        {path: 'settings', loadChildren: async () => (await import('../../../modules/settings')).SettingsModule},
        {path: 'notifications', loadChildren: async () => (await import('../../../modules/notification')).NotificationModule},

        {path: 'crm', loadChildren: async () => (await import('../../../modules/crm')).CrmModule},

        {path: '', redirectTo: 'dashboard', pathMatch: 'full'}
    ],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LayoutRoutingModule
{
}
