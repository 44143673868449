import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {VehicleService, VehicleType} from '../interfaces';
import {ApiEndpointsService} from './api-endpoint.service';
import {ApiHttpService} from './api-http.service';

@Injectable()
export class VehicleTypeService extends ApiHttpService
{
    constructor(public http: HttpClient, public apiEndpointService: ApiEndpointsService)
    {
        super(http);
    }

    /**
     * Tüm araç tiplerini almak için HTTP isteği.
     */
    public all(): Observable<VehicleType[]>
    {
        return this.get(this.apiEndpointService.createUrl('vehicle-types', true));
    }

    /**
     * Verilen araç tipi ile ilişkili tüm aktif hizmetleri alın.
     */
    public getServices(vehicleTypeId: string): Observable<VehicleService[]>
    {
        return this.get(this.apiEndpointService.createUrl(`vehicle-types/${vehicleTypeId}/services`, true));
    }
}
