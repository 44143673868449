import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiEndpointsService} from '../../../../../core/services/api-endpoint.service';
import {ApiHttpService} from '../../../../../core/services/api-http.service';
import {VerificationCodeResponse} from '../models/verification-code-response';

@Injectable()
export class VerificationCodeService extends ApiHttpService
{
    constructor(public http: HttpClient, public apiEndpointService: ApiEndpointsService)
    {
        super(http);
    }

    generate(formData: any): Observable<VerificationCodeResponse>
    {
        return this.post(this.apiEndpointService.createUrl('generate-verification-code'), formData);
    }
}
