import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {FocusTrapModule} from 'primeng/focustrap';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RippleModule} from 'primeng/ripple';
import {InputDialogComponent} from './components/input-dialog.component';

const MODULES = [
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    RippleModule,
    ButtonModule,
    InputTextareaModule,
    FocusTrapModule,
];

const COMPONENTS = [
    InputDialogComponent,
];

const PIPES = [];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
})
export class InputDialogModule
{
}
