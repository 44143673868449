import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageActionsComponent} from './components/page-actions.component';

const MODULES = [];

const COMPONENTS = [
    PageActionsComponent,
];

const PIPES = [];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
})
export class PageActionsModule
{
}
