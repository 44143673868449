import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AuthProviderOptions} from '../auth-provider-options';
import {getDeepFromObject} from '../../helpers';
import {AuthTokenClass} from '../../services/token/auth-token/auth-token.interface';
import {AuthSimpleToken} from '../../services/token/token';

export interface PasswordStrategyModule
{
    endpoint?: string;
    method?: string;
    redirect?: {
        success?: string | null;
        failure?: string | null;
    };
    requireValidToken?: boolean;
    defaultErrors?: string[];
    defaultMessages?: string[];
}

export interface PasswordStrategyReset extends PasswordStrategyModule
{
    resetPasswordTokenKey?: string;
}

export interface PasswordStrategyToken
{
    class?: AuthTokenClass;
    key?: string;
    // tslint:disable-next-line:ban-types
    getter?: Function;
}

export interface PasswordStrategyMessage
{
    key?: string;
    // tslint:disable-next-line:ban-types
    getter?: Function;
}

export class PasswordAuthStrategyOptions extends AuthProviderOptions
{
    baseEndpoint = '/api/auth/';

    login?: boolean | PasswordStrategyModule = {
        endpoint         : 'token',
        method           : 'post',
        requireValidToken: true,
        redirect         : {
            success: '/',
            failure: null,
        },
        defaultErrors    : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages  : ['Başarıyla giriş yaptınız.'],
    };

    register?: boolean | PasswordStrategyModule = {
        endpoint         : 'register',
        method           : 'post',
        requireValidToken: true,
        redirect         : {
            success: '/',
            failure: null,
        },
        defaultErrors    : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages  : ['You have been successfully registered.'],
    };

    requestPass?: boolean | PasswordStrategyModule = {
        endpoint       : 'request-pass',
        method         : 'post',
        redirect       : {
            success: '/',
            failure: null,
        },
        defaultErrors  : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages: ['Reset password instructions have been sent to your email.'],
    };

    resetPass?: boolean | PasswordStrategyReset = {
        endpoint             : 'reset-pass',
        method               : 'put',
        redirect             : {
            success: '/',
            failure: null,
        },
        resetPasswordTokenKey: 'reset_password_token',
        defaultErrors        : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages      : ['Your password has been successfully changed.'],
    };

    revoke?: boolean | PasswordStrategyReset = {
        endpoint       : 'revoke',
        method         : 'delete',
        redirect       : {
            success: '/auth/login',
            failure: null,
        },
        defaultErrors  : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages: ['You have been successfully logged out.'],
    };

    refreshToken?: boolean | PasswordStrategyModule = {
        endpoint         : 'refresh-token',
        method           : 'post',
        requireValidToken: true,
        redirect         : {
            success: null,
            failure: null,
        },
        defaultErrors    : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
        defaultMessages  : ['Your token has been successfully refreshed.'],
    };

    // validatePassword?: boolean | PasswordStrategyModule = {
    //     endpoint         : 'validate-password',
    //     method           : 'post',
    //     requireValidToken: true,
    //     defaultErrors    : ['Bir şeyler ters gitti lütfen tekrar deneyin.'],
    //     defaultMessages  : ['You have been successfully registered.'],
    // };

    token?: PasswordStrategyToken = {
        class : AuthSimpleToken,
        key   : 'access_token',
        getter: (module: string, res: HttpResponse<any>, options: PasswordAuthStrategyOptions) => getDeepFromObject(
            res.body,
            options.token.key,
        ),
    };

    errors?: PasswordStrategyMessage = {
        key   : 'errors',
        getter: (module: string, res: HttpErrorResponse, options: PasswordAuthStrategyOptions) => getDeepFromObject(
            res.error,
            options.errors.key,
            options[module].defaultErrors,
        ),
    };

    messages?: PasswordStrategyMessage = {
        key   : 'messages',
        getter: (module: string, res: HttpResponse<any>, options: PasswordAuthStrategyOptions) => getDeepFromObject(
            res.body,
            options.messages.key,
            options[module].defaultMessages,
        ),
    };
}

export const passwordProviderOptions: PasswordAuthStrategyOptions = new PasswordAuthStrategyOptions();
