import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {ComponentBase} from '../../../../../core/classes/component-base';
import {AccountType} from '../../../change-password-dialog/lib/models';
import {VerificationCodeResponse} from '../models/verification-code-response';
import {VerificationCodeService} from '../services/verification-code.service';

@Component({
    selector: 'send-verification-code-dialog',
    templateUrl: './send-verification-code-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SendVerificationCodeDialogComponent extends ComponentBase implements OnInit, OnDestroy
{
    loading: boolean;

    accountType: AccountType;
    accountId: number;
    driverFullname: string;

    response: VerificationCodeResponse;

    public form = new FormGroup({
        account_type: new FormControl('', Validators.required),
        account_id: new FormControl('', Validators.required),
        send_as_sms: new FormControl(false),
    });

    constructor(private $verificationCodeService: VerificationCodeService, public ref: DynamicDialogRef, public config: DynamicDialogConfig)
    {
        super();
        const data = config.data;
        this.accountType = data.account_type;
        this.accountId = data.account_id;
        this.driverFullname = data.driver_fullname;
    }

    get controlAccountType()
    {
        return this.form.get('account_type');
    }

    get controlAccountId()
    {
        return this.form.get('account_id');
    }

    get controlSendAsSms()
    {
        return this.form.get('send_as_sms');
    }

    onSubmit(): void
    {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.$appService.clearMessages();
            this.$appService.showMessage('Formda boş veya geçersiz alanlar mevcut. Kontrol ettikten sonrar tekrar deneyin.');
            return;
        }

        this.loading = true;
        this.$verificationCodeService.generate(this.form.value).pipe(takeUntil(this.destroy$)).subscribe(
            response => {
                this.$appService.clearMessages();
                this.$appService.handleResponseMessage(response);
                this.response = response;
                this.loading = false;
                this.controlSendAsSms.setValue(false);
            },
            response => this.$appService.handleHttpErrors(response, this)
        );
    }

    onCanceled(): void
    {
        this.ref.close(false);
    }

    ngOnInit(): void
    {
        if (!this.accountType || !this.accountId) {
            this.$appService.showToastMessage('Geçersiz parametreler.', 'error', 'Oops...');
            this.ref.close(false);
            return;
        }

        this.controlAccountType.setValue(this.accountType);
        this.controlAccountId.setValue(this.accountId as any);
    }

    ngOnDestroy(): void
    {
        super.ngOnDestroy();
    }
}
