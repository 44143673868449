import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {TokenStorage} from './token-storage';
import {AuthToken} from './auth-token/auth-token';

@Injectable()
export class TokenService
{
    protected token$: BehaviorSubject<AuthToken> = new BehaviorSubject(null);

    constructor(protected tokenStorage: TokenStorage)
    {
        this.publishStoredToken();
    }

    /**
     * Mevcut jetonu gözlemlenebilir şekilde alın.
     *
     * @returns {Observable<AuthToken>}
     */
    get(): Observable<AuthToken>
    {
        const token = this.tokenStorage.get();

        return of(token);
    }

    /**
     * Sets a token into the storage. This method is used by the AuthService automatically.
     *
     * @param {AuthToken} token
     *
     * @returns {Observable<any>}
     */
    set(token: AuthToken): Observable<null>
    {
        this.tokenStorage.set(token);
        this.publishStoredToken();

        return of(null);
    }

    /**
     * Jetonu ve yayınlanan jeton değerini kaldırın.
     *
     * @returns {Observable<any>}
     */
    clear(): Observable<null>
    {
        this.tokenStorage.clear();
        this.publishStoredToken();

        return of(null);
    }

    /**
     * Jeton değiştiğinde yayınlar.
     *
     * @returns {Observable<AuthToken>}
     */
    tokenChange(): Observable<AuthToken>
    {
        return this.token$
            .pipe(
                filter(value => !!value),
                share(),
            );
    }

    protected publishStoredToken()
    {
        this.token$.next(this.tokenStorage.get());
    }
}
