import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './lib/components/login.component';
import {AutoLoginGuard} from './lib/guards/auto-login.guard';

const routes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent, canLoad: [AutoLoginGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule
{
}
