import {AuthPayload} from './auth-payload';

export abstract class AuthToken
{
    protected payload: AuthPayload = null;

    abstract getValue(): string;

    abstract isValid(): boolean;

    abstract getOwnerStrategyName(): string;

    abstract getCreatedAt(): Date;

    abstract toString(): string;

    getName(): string
    {
        return (this.constructor as any).NAME;
    }

    getPayload(): AuthPayload
    {
        return this.payload;
    }
}
