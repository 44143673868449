import {HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {deepExtend, getDeepFromObject} from '../helpers';
import {AuthProviderOptions} from './auth-provider-options';
import {AuthToken} from '../services/token/auth-token/auth-token';
import {AuthResult} from '../services/auth-result';
import {authCreateToken} from '../services/token/token';
import {AuthIllegalTokenError} from '../exceptions/token/auth-illegal-token';

export abstract class AuthProvider
{
    /**
     * Oturum sağlayıcı adı.
     *
     * @protected
     * @type {string}
     */
    protected name: string;

    protected baseEndpoint: string;

    /**
     * Varsayılan ayarlar.
     *
     * @protected
     * @type {AuthProviderOptions}
     */
    protected defaultOptions: AuthProviderOptions;

    protected options: AuthProviderOptions;

    protected requireValidToken: boolean;

    getOption(key: string): any
    {
        return getDeepFromObject(this.options, key, null);
    }

    // we should keep this any and validation should be done in `register` method instead otherwise it won't be possible to pass an empty object
    setOptions(options: any): void
    {
        this.options = deepExtend({}, this.defaultOptions, options);
    }

    createToken<T extends AuthToken>(value: any, failWhenInvalidToken?: boolean): T
    {
        const token = authCreateToken<T>(this.getOption('token.class'), value, this.getName());

        if (failWhenInvalidToken && !token.isValid()) {
            throw new AuthIllegalTokenError('Jeton boş veya geçersiz.');
        }
        return token;
    }

    getName(): string
    {
        return this.name;
    }

    abstract authenticate(data?: any): Observable<AuthResult>;

    abstract register(data?: any): Observable<AuthResult>;

    abstract requestPassword(data?: any): Observable<AuthResult>;

    abstract resetPassword(data?: any): Observable<AuthResult>;

    abstract logout(): Observable<AuthResult>;

    abstract refreshToken(data?: any): Observable<AuthResult>;

    abstract validatePassword(password?: string): Observable<AuthResult>;

    protected createFailResponse(data?: any): HttpResponse<any>
    {
        return new HttpResponse<any>({body: {}, status: 401});
    }

    protected createSuccessResponse(data?: any): HttpResponse<any>
    {
        return new HttpResponse<any>({body: {}, status: 200});
    }

    protected getActionEndpoint(action: string): string
    {
        const baseEndpoint: string = this.baseEndpoint;

        return action ? baseEndpoint + action : '';
    }
}
