import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {NgPipesModule} from 'ngx-pipes';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BlockUIModule} from 'primeng/blockui';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {MenuModule} from 'primeng/menu';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MultiSelectModule} from 'primeng/multiselect';
import {PasswordModule} from 'primeng/password';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {FileUploadModule as NgxFileUploadModule} from '@iplab/ngx-file-upload';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ChangePasswordDialogModule} from './components/change-password-dialog';
import {DatatableModule} from './components/datatable';
import {InputDialogModule} from './components/input-dialog';
import {PageActionsModule} from './components/page-actions';
import {SendVerificationDialogModule} from './components/send-verification-code-dialog';
import {MeterToKilometerModule} from './pipes/meter-to-kilometer/meter-to-kilometer.module';
import {MomentModule} from './pipes/moment/moment.module';
import {PhonePipeModule} from './pipes/phone/phone.module';
import {OrderModule} from 'ngx-order-pipe';

const MODULES = [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CheckboxModule,
    ChipsModule,
    DropdownModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    MultiSelectModule,
    PasswordModule,
    RadioButtonModule,
    RatingModule,
    SliderModule,
    SelectButtonModule,
    ToggleButtonModule,
    ButtonModule,
    SplitButtonModule,
    VirtualScrollerModule,
    TabViewModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    TooltipModule,
    DialogModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BlockUIModule,
    ChipModule,
    ProgressBarModule,
    TagModule,
    InputNumberModule,
    InputTextareaModule,
    CalendarModule,
    ListboxModule,
    RippleModule,
    MenuModule,
    ScrollingModule,
    PhonePipeModule,
    AutoCompleteModule,
    MomentModule,
    DatatableModule,
    TabMenuModule,
    LeafletModule,
    LeafletDrawModule,
    NgxFileUploadModule,
    InputDialogModule,
    ChangePasswordDialogModule,
    SendVerificationDialogModule,
    PageActionsModule,
    OrderModule,
    MeterToKilometerModule,
    NgPipesModule,
];

const COMPONENTS = [];

const PROVIDERS = [
    DatePipe,
    CurrencyPipe,
];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [CommonModule, ...MODULES],
    declarations: [...COMPONENTS],
    providers: [...PROVIDERS],
})
export class SharedModule
{
    public static forRoot(): ModuleWithProviders<SharedModule>
    {
        return {
            ngModule: SharedModule,
            providers: [
                ConfirmationService,
                MessageService,
                DialogService,
            ],
        };
    }
}
