import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TokenService} from '../auth';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor
{
    constructor(private router: Router, private $tokenService: TokenService)
    {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    this.$tokenService.clear();
                    this.router.navigateByUrl('/auth/login');
                }

                return throwError(error);
            })
        );
    }
}
