import {AuthIllegalTokenError} from './auth-illegal-token';

export class AuthEmptyTokenError extends AuthIllegalTokenError
{
    constructor(message: string)
    {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
