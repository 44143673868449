/// <reference types="datatables.net"/>
/// <reference types="datatables.net-select"/>
/// <reference types="datatables.net-fixedheader"/>
import {
    ChangeDetectorRef,
    Component, DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ApiHttpService} from '../../../../../core/services/api-http.service';
import {DataTableColumnSettings, DatatableOrder, DataTableParameters} from '../datatable.model';
import {DatatableService} from '../datatable.service';
import {Events} from '../events';
import ChangeEvent = JQuery.ChangeEvent;

@Component({
    selector: 'datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'datatable'
    }
})
export class Datatable extends Events implements OnInit, DoCheck, OnChanges, OnDestroy
{
    /**
     * Bileşen kimliği.
     *
     * @type {string}
     */
    id = `datatable-${Datatable.nextId++}`;

    /**
     * Tablo bilgi alanının görünüp görünmeyeceği.
     *
     * @type {boolean}
     */
    @Input() info = true;

    /**
     * Sayfalandırmanın etkin olup olmadığı.
     *
     * @type {boolean}
     */
    @Input() pagination = true;

    /**
     * Sıralama özelliğinin etkin olup olmadığı.
     *
     * @type {boolean}
     */
    @Input() ordering = true;

    /**
     * Görünleme uzunluğu kontrolünün etkin olup olmadığı.
     *
     * @type {boolean}
     */
    @Input() lengthChange = true;

    /**
     * Filtreleme kontrolünün etkin olup olmadığı.
     *
     * @type {boolean}
     */
    @Input() searching = true;

    /**
     * Tabloda seçim yapılabilip yapılamayacağı.
     *
     * @type {boolean|string|DataTables.SelectSettings}
     */
    @Input() select: boolean | string | DataTables.SelectSettings = false;

    /**
     * Sayfa başına kayıt sayısı.
     *
     * @type {number}
     */
    @Input() rows: number;

    /**
     * Sayfa başına öğe listesi.
     *
     * @type {any[]}
     */
    @Input() lengthMenu = [
        {title: '10 kayıt', value: 10},
        {title: '20 kayıt', value: 20},
        {title: '50 kayıt', value: 50},
        {title: '100 kayıt', value: 100},
        {title: 'Tümü', value: -1},
    ];

    @Input() createdRow = null;

    /**
     * Toplam öğe sayısı.
     *
     * @type {number}
     */
    totalCount = 0;

    /**
     * Filtrelenmiş öğe sayısı.
     *
     * @type {number}
     */
    filteredRowsCount = 0;

    /**
     * Seçilmiş öğe sayısı.
     *
     * @type {number}
     */
    selectedItemsCount = 0;

    /**
     * Tablo öğeleri.
     *
     * @type {Array}
     */
    items: any[] = [];

    /**
     * Seçilmiş tablo satırları.
     *
     * @type {Array}
     */
    selectedItems: any[] = [];

    _columns: DataTableColumnSettings[] = [];

    /**
     * DataTables yapılandırması.
     *
     * @type {DataTables.Settings}
     */
    private _options: DataTables.Settings = {};

    /**
     * Veri kaynağı adresi.
     *
     * @type {string}
     */
    private _source: string;

    /**
     * Sunucuya gönderilecek filtreleme parametreleri.
     *
     * @return {Object}
     */
    private _parameters: any;

    /**
     * Sütunlar değer biçimlendirme dizisi.
     *
     * @return {Array}
     */
    private _columnFormats: any[];

    /**
     * DataTables örneği.
     */
    private instance: DataTables.Api;

    private _destroy = new Subject<void>();

    /**
     * DataTables oluşturmayı tetiklemek için kullanılır.
     *
     * @type {Subject<any>}
     */
    @Input() dtTrigger: Subject<any>;

    /**
     * JQuery kütüphanesi [DataTables] (datatables.net) tarafından oluşturulan DataTable örneği.
     *
     * @type {Promise<DataTables.Api>}
     */
    dtInstance: Promise<DataTables.Api>;

    /**
     * İlk yükleme olup olmadığı.
     *
     * @type {boolean}
     */
    initialLoading = true;

    /**
     * Ajax işlemlerinde verinin yüklenip yüklenmediği.
     *
     * @type {boolean}
     */
    loading: boolean;

    /**
     * Herhangi bir hatanın olup olmadığı.
     *
     * @type {boolean}
     * @private
     */
    _error = false;

    /**
     * Tablo referansı.
     *
     * @type {ElementRef}
     */
    @ViewChild('tableRef', {static: true}) tableRef: ElementRef;

    /**
     * Seçim menüsü referansı.
     *
     * @type {ElementRef<HTMLInputElement>}
     */
    @ViewChild('chkSelect', {static: true}) _chkSelect: ElementRef<HTMLInputElement>;

    /**
     * Tabloda arama yapabilmek için form kontrolü.
     *
     * @type {FormControl}
     * @private
     */
    _searchControl = new FormControl();

    static nextId = 0;

    @HostBinding('class.loading') get class()
    {
        return this.loading || this.initialLoading;
    }

    constructor(private elementRef: ElementRef, private $apiService: ApiHttpService, private config: DatatableService, private cdr: ChangeDetectorRef)
    {
        super();
        this.rows = config.get('rows');
        this.options.language = config.get('language');
    }

    /**
     * Verilen sütun adına göre sütunu gösterin.
     *
     * @param {string} columnName   Sütun adı.
     *
     * @return {void}
     */
    public showColumn(columnName: string): void
    {
        if (!this.hasColumn(columnName)) {
            return;
        }

        if (!this.instance) {
            this.dtInstance.then(() => this.showColumn(columnName));
            return;
        }

        const column = this.instance.column(this.getIndexByColumnName(columnName));
        column.visible(true);
    }

    /**
     * Verilen sütun adına göre sütunu gizleyin.
     *
     * @param {string} columnName   Sütun adı.
     *
     * @return {void}
     */
    public hideColumn(columnName: string): void
    {
        if (!this.hasColumn(columnName)) {
            return;
        }

        if (!this.instance) {
            this.dtInstance.then(() => this.hideColumn(columnName));
            return;
        }

        const column = this.instance.column(this.getIndexByColumnName(columnName));
        column.visible(false);
    }

    /**
     * Verilen sütun adına göre görünürlüğünü değiştirin.
     *
     * @param {string} columnName   Sütun adı.
     *
     * @return {void}
     */
    public toggleColumn(columnName: string): void
    {
        if (!this.hasColumn(columnName)) {
            return;
        }

        if (!this.instance) {
            this.dtInstance.then(() => this.toggleColumn(columnName));
            return;
        }

        const column = this.instance.column(this.getIndexByColumnName(columnName));
        column.visible(!column.visible());
    }

    /**
     * Tablo öğelerini ayarlayın.
     *
     * @param {any[]} items
     */
    public setItems(items: any[]): void
    {
        this.items = items;
    }

    /**
     * Veriyi yeniden yükleyin.
     *
     * @param {(json: any) => void} callback
     * @param {boolean} resetPaging
     *
     * @return {void}
     */
    public reload(callback?: ((json: any) => void), resetPaging: boolean = false): void
    {
        if (!this.instance) {
            return;
        }
        this.clearSelectedItems();
        this.instance.ajax.reload(callback, resetPaging);
    }

    /**
     * Tüm tabloda filtreleme yapın.
     *
     * @param {string} input
     * @param {boolean} regex
     * @param {boolean} smart
     * @param {boolean} caseInsen
     *
     * @return {DataTables.Api}
     */
    public search(input: string, regex?: boolean, smart?: boolean, caseInsen?: boolean): DataTables.Api
    {
        return this.instance.search(input, regex, smart, caseInsen).draw();
    }

    /**
     * Adı verilen sütunda filtreleme yapın.
     *
     * @param {string} column
     * @param {string} value
     *
     * @return {void}
     */
    public columnFilter(column: string, value: string): void
    {
        const columnIndex = this.getIndexByColumnName(column);
        this.instance.column(columnIndex).search(value).draw();
    }

    public getSelectedItems()
    {
        return this.instance.rows({selected: true});
    }

    /**
     * Tablodaki tüm öğeleri işaretleyin.
     *
     * @return {void}
     */
    public selectAll(): void
    {
        this.instance.rows().select();
    }

    /**
     * Tablodaki seçili tüm öğelerin işaretini kaldırın.
     *
     * @return {void}
     */
    public deselect(): void
    {
        this.instance.rows().deselect();

        let cells = this.instance.column(0).nodes();

        for (let i = 0; i < cells.length; i += 1) {
            cells[i].querySelector('input[type="checkbox"]').checked = false;
        }
    }

    /**
     * Seçilen öğeleri kaldırın.
     *
     * @return {void}
     */
    public clearSelectedItems(): void
    {
        if (this.select) {
            $(`thead th.checkbox-select-all input[type="checkbox"]`).prop({
                indeterminate: false,
                checked: false,
            });
            this.selectedItems = [];
            this.selectedItemsCount = 0;
            this.selectChanged.emit(this.selectedItems);
            this.cdr.detectChanges();
        }
    }

    /**
     * DataTables örneğini alın.
     *
     * @return {DataTables.Api}
     */
    public getInstance(): DataTables.Api
    {
        return this.instance;
    }

    /**
     * DataTables örneğini yok edin.
     *
     * @return {void}
     */
    public destroy(): void
    {
        if (this.instance) {
            this.instance.destroy(true);
        }
    }

    /**
     * Tablo referansını alın.
     *
     * @return {HTMLTableElement}
     */
    public getTableRef(): HTMLTableElement
    {
        return this.tableRef.nativeElement;
    }

    public hasColumn(columnName: string): boolean
    {
        return this.columns.findIndex(column => column.name == columnName) !== -1;
    }

    /**
     * Sütun sıralamasını hazırlayın.
     *
     * @param {DatatableOrder[] | Array<number | string> | Array<Array<number | string>>} value
     *
     * @return {Array<Array<number | string>>}
     */
    private prepareOrder(value: DatatableOrder[] | Array<(number | string)> | Array<Array<(number | string)>>): Array<Array<(number | string)>>
    {
        const orders = value || [];
        const result = [];

        orders.forEach(order => {
            const columnIndex = this.getIndexByColumnName(order.name);
            result.push([columnIndex, order.dir]);
        });

        return result;
    }

    /**
     * DataTables parametrelerini hazırlayın.
     *
     * @param {DataTableParameters} parameters
     *
     * @return {any}
     */
    private prepareDatatableParameters(parameters: DataTableParameters): any
    {
        const params: any = {};
        params.draw = parameters.draw;
        params.limit = parameters.length;
        params.start = parameters.start;
        params.search = parameters.search;
        params.columns = parameters.columns;
        params.order = parameters.order.map(obj => {
            const column = this.getColumnByIndex(obj.column);
            const order: any = {
                name: column.name,
                index: obj.column,
                dir: obj.dir
            };
            if (column.hasOwnProperty('table')) {
                order.fullname = `${column.table}.${column.name}`;
            }
            return order;
        });

        return params;
    }

    /**
     * Verilen sütun indeksine göre sütun nesnesini alın.
     *
     * @param {number} index
     *
     * @return {string|undefined}
     */
    private getColumnByIndex(index: number): DataTableColumnSettings
    {
        if (!this.columns[index]) {
            return undefined;
        }

        return this.columns[index];
    }

    /**
     * Verilen sütun indeksine göre sütun adını alın.
     *
     * @param {number} index
     *
     * @return {string|undefined}
     */
    private getColumnNameByIndex(index: number): any
    {
        if (!this.columns[index]) {
            return undefined;
        }

        return this.columns[index].name;
    }

    /**
     * Verilen sütun adına göre sütun indexini alın.
     *
     * @param {string} columnName
     *
     * @return {string|undefined}
     */
    private getIndexByColumnName(columnName: string): any
    {
        return this.columns.findIndex(column => column.name === columnName);
    }

    /**
     * DataTables örneğini başlatın.
     *
     * @return {void}
     */
    private init(): void
    {
        this.options.dom = 't<"table-footer"i<"fill"><"table-footer-extras">p>';
        this.options.info = this.info;
        this.options.ordering = this.ordering;
        this.options.paging = this.pagination;
        this.options.pagingType = 'full_numbers';
        this.options.lengthChange = this.pagination;
        this.options.autoWidth = false;
        this.options.orderCellsTop = true;
        this.options.responsive = {
            details: {
                type: 'column',
                target: 'tr'
            }
        };
        this.options.fixedHeader = {
            header: true,
            footer: false,
        };
        if (this.createdRow) {
            this.options.createdRow = this.createdRow;
        }
        this.options.serverSide = true;
        this.options.processing = false;
        this.options.deferRender = true;
        this.options.retrieve = true;
        this.options.order = this.order;
        this.options.pageLength = this.rows;
        this.options.columns = this.columns;
        this.options.searchDelay = 300;
        this.options.ajax = (params: DataTableParameters, callback) => {
            if (!this.source) {
                return;
            }

            // Parametreleri değiştir
            const parameters = Object.assign({query: this.parameters}, this.prepareDatatableParameters(params));

            this.$apiService.post(this.source, parameters).pipe(takeUntil(this._destroy)).subscribe(
                response => {
                    this._error = false;
                    this.setItems(response.items);

                    callback({
                        draw: response.draw,
                        data: response.items,
                        recordsTotal: response.total,
                        recordsFiltered: response.filtered_count,
                    });

                    this.totalCount = response.total;
                    this.filteredRowsCount = response.filtered_count;
                },
                response => {
                    console.error('[DT] Veri alınırken hata oluştu', response);
                    this.loading = false;
                    this._error = true;
                    this.setItems([]);

                    callback({
                        draw: response.draw,
                        data: [],
                        recordsTotal: 0,
                        recordsFiltered: 0,
                    });
                }
            );
        };

        if (this.select) {
            this.options.select = {
                style: 'multi',
                selector: 'td:first-child input[type="checkbox"]',
            };
        }

        this.dtInstance = new Promise((resolve) => Promise.resolve(this.options).then(dtOptions => setTimeout(() => {
            const options = Object.assign({}, dtOptions, this.options);
            this.instance = $(this.tableRef.nativeElement).DataTable(options);
            const instance = this.instance;

            this.registerValueChanges();

            instance.on('init', (e, settings: DataTables.Settings, response) => {

                this.loaded.emit({settings, response});
                this.initialLoading = false;

                const select = $('<select/>', {
                    class: 'form-control form-control-sm lenght-control',
                    id: `lenght-control_${this.id}`
                });
                select.on('change', (ev: ChangeEvent) => {
                    const value = ev.target.value;
                    this.instance.page.len(value).draw();
                });
                for (const selectItem of this.lengthMenu) {
                    select.append(`<option value="${selectItem.value}">${selectItem.title}</option>`);
                }

                if ($('.table-footer-extras').find('.lenght-control').length === 0) {
                    $(`.table-footer-extras`).append(select);
                }
                select.val(this.rows);

                if (this.select) {
                    this.buildCheckbox();
                }

            });

            instance.on('search', (e, settings: DataTables.Settings) => {
                this.searched.emit(settings);
            });

            instance.on('page', () => {
                const info = instance.page.info();
                this.pageChanged.emit(info);
            });

            instance.on('preXhr', (e, settings: DataTables.Settings, data) => {
                this.beforeXhr.emit({data, settings});
            });

            instance.on('processing', (e, settings: DataTables.Settings, processing: boolean) => {
                this.loading = processing;
                this.processing.emit({processing, settings});
            });

            instance.on('order', (e, settings: DataTables.Settings) => {
                const order = instance.order();
                this.orderChanged.emit({settings, order});
            });

            instance.on('draw', (e, settings: DataTables.Settings) => {
                if (this.temp.hasOwnProperty('selectAll')) {

                    const state = this.temp['selectAll'];

                    if (state) {
                        this.instance.rows().select();
                    }

                    this.changeCheckboxesColumn(state);

                    delete this.temp['selectAll'];
                }
                this.draw.emit({e, settings})
            });

            instance.on('length', (e, settings: DataTables.Settings, len: number) => {
                this.lengthChanged.emit({settings, len});
            });

            instance.on('select deselect', (e, datatable: DataTables.Api, type: string, indexes: any[]) => this.onSelectDeselect(e, datatable, type, indexes));

            instance.on('user-select', (e, datatable: DataTables.Api, type: string, cell, originalEvent) => {

            });

            instance.on('error', (e, settings: DataTables.Settings, techNote: number, message: string) => {
                this._error = true;
                this.error.emit({settings, techNote, message});
            });

            resolve(this.instance);
        })));
    }

    private onSelectDeselect(e, datatable: DataTables.Api, type: string, indexes: any[])
    {
        try {
            const selectedRows = this.getSelectedItems();
            const selectedItems = selectedRows.data();

            const newarray = [];
            for (let i = 0; i < selectedItems.length; i++) {
                newarray.push(selectedItems[i]);
            }

            this.selectedItems = newarray;
            this.selectedItemsCount = selectedRows.count();
            this.selectChanged.emit(newarray);
            this.cdr.detectChanges();
            const selectAllControl = $(`thead th.checkbox-select-all input[type="checkbox"]`, $(this.instance.table(0).container()));

            if (this.selectedItemsCount === 0) {
                selectAllControl.prop({
                    checked: false,
                    indeterminate: false
                });
            }
            else if (this.selectedItemsCount === this.totalCount) {
                selectAllControl.prop({
                    checked: true,
                    indeterminate: false,
                });
            }
            else {
                selectAllControl.prop({
                    checked: true,
                    indeterminate: true
                });
            }
        }
        catch (e) {
        }
    }

    private temp: any = {};

    private buildCheckbox()
    {
        const $colHeader = $(this.instance.column(0).header());
        const self: this = this;
        $colHeader.html(`<div class="form-check"><input class="form-check-input" type="checkbox"/></div>`)
        $colHeader.addClass('checkbox-select-all');

        const $tableContainer = $(this.instance.table(0).container());

        $tableContainer.on('click', 'thead th.checkbox-select-all input[type="checkbox"]', function() {
            const state = this.checked;

            if (state) {
                self.temp['selectAll'] = true;
                self.instance.page.len(-1).draw();
            }
            else {
                self.temp['selectAll'] = false;
                self.instance.rows().deselect();
                self.changeCheckboxesColumn(false);
            }
        });
    }

    private changeCheckboxesColumn(state: boolean): void
    {
        const cells = this.instance.column(0).nodes();

        for (let i = 0; i < cells.length; i += 1) {
            cells[i].querySelector('input[type="checkbox"]').checked = state;
        }
    }

    /**
     * Değer değişimlerini gözlemleyin.
     *
     * @return {void}
     */
    private registerValueChanges(): void
    {
        if (this.searching) {
            // Arama girişinde bir değişiklik olduğunda
            this._searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this._destroy)).subscribe(value => {
                this.search(value);
            });
        }
    }

    @Input()
    get options(): DataTables.Settings
    {
        return this._options;
    }

    set options(options: DataTables.Settings)
    {
        this._options = options;
    }

    @Input()
    get columns(): DataTableColumnSettings[]
    {
        return this._columns;
    }

    set columns(columns: DataTableColumnSettings[])
    {
        columns.map(column => {
            if (column.hasOwnProperty('name') && !column.hasOwnProperty('data')) {
                column['data'] = column.name;
            }
            if (column.hasOwnProperty('data') && !column.hasOwnProperty('name')) {
                column['name'] = column.data as string;
            }
            if (column.name == 'actions') {
                column['data'] = null;
                column['defaultContent'] = null;
            }
        });

        if (this.select) {
            columns.unshift({
                name: 'checkbox',
                width: '1%',
                orderable: false,
                searchable: false,
                className: 'checkbox',
                render: (data, type, row: any) => {
                    return `<div class="form-check"><input class="form-check-input" type="checkbox" value="${data && data?.id ? data.id : ''}"/></div>`;
                },
            });
        }

        this._columns = columns;
    }

    @Input()
    get columnFormats()
    {
        return this._columnFormats;
    }

    set columnFormats(columns)
    {
        this._columnFormats = columns;

        this._columns = this._columns.map((item) => {
            const _formatter = columns.find(_format => _format.name === item.name);
            if (_formatter) {
                return Object.assign(_formatter, item);
            }
            return item;
        });
    }

    @Input()
    get order(): Array<(number | string)> | Array<Array<(number | string)>>
    {
        return this.options.order;
    }

    set order(value: Array<(number | string)> | Array<Array<(number | string)>>)
    {
        this.options.order = this.prepareOrder(value);
    }

    @Input()
    get source(): string
    {
        return this._source;
    }

    set source(source: string)
    {
        if (this._source !== source) {
            this._source = source;
            this.sourceChanged.emit(source);
            this.clearSelectedItems();
        }
    }

    @Input()
    get parameters(): any
    {
        return this._parameters || {};
    }

    set parameters(parameters: any)
    {
        if (this._parameters !== parameters) {
            this._parameters = parameters;
            this.parametersChanged.emit(parameters);
            this.clearSelectedItems();
        }
    }

    ngOnInit(): void
    {
        if (this.dtTrigger) {
            this.dtTrigger.subscribe(() => this.init());
        }
        else {
            this.init();
        }
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if ('source' in changes && !changes['source'].isFirstChange()) {
            // console.log('[DT] Source değişti', changes['source'].currentValue);
            this.reload(null, true);
            this._parameters = {};
        }

        if ('parameters' in changes && !changes['parameters'].isFirstChange()) {
            this.reload(null, true);
        }
    }

    ngDoCheck(): void
    {
        if (this.select && !this.initialLoading) {
            try {
                if (this.selectedItemsCount > 0) {
                    this.elementRef.nativeElement.querySelector('.select-info').style.visibility = 'visible';
                }
                else {
                    this.elementRef.nativeElement.querySelector('.select-info').style.visibility = 'hidden';
                }
            }
            catch (e) {
            }
        }
    }

    ngOnDestroy(): void
    {
        super.ngOnDestroy();
        this.items = null;
        this.selectedItemsCount = null;
        this.selectedItems = null;
        this._columns = null;
        this._columnFormats = null;
        this._destroy.unsubscribe();
        if (this.dtTrigger) {
            this.dtTrigger.unsubscribe();
        }
        this.destroy();
    }
}
