import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable()
export class AppService
{
    constructor(public $messageService?: MessageService)
    {
    }

    public handleHttpErrors(response: HttpErrorResponse, context: any, callback?: any): void
    {
        if (context.hasOwnProperty('loading')) {
            context.loading = false;
        }

        if (response.status === 422) {
            const errors = response.error.errors;
            let template = `<p>Formda geçersiz alanlar mevcut. Kontrol ettikten sonra tekrar deneyin.</p>`;

            template += '<ul>';
            Object.keys(errors).forEach(key => {
                template += `<li>${errors[key]}</li>`;
            });

            template += '</ul>';

            this.showMessage(template, 'warn', 'Uyarı');
        }
        else if (response.status === 500) {
            this.showMessage(response.error.message, 'error', 'Bir şeyler ters gitti...');
        }

        callback && callback();
    }

    public handleResponseMessage(response): void
    {
        this.showToastMessage(response?.message, response?.severity);
    }

    public showToastMessage(message: string, severity?: Severity, summary?: string, life?: number, key = 'notifications'): void
    {
        let defaultTitle;

        switch (severity) {
            case 'success':
                defaultTitle = 'Başarılı';
                break;
            case 'error':
                defaultTitle = 'Başarısız';
                break;
            case 'warn':
                defaultTitle = 'Uyarı';
                break;
            default:
                defaultTitle = 'Başarılı';
        }

        // this.$messageService.clear(key);
        this.$messageService.add({
            key: key,
            severity: severity || 'success',
            summary: summary || defaultTitle,
            detail: message,
            life: life || 10000,
        });
    }

    public showMessage(message: string, severity?: Severity, summary?: string): void
    {
        const messageKey = this.inDialog() ? 'messages.dialog' : 'messages';

        this.$messageService.clear(messageKey);
        this.$messageService.add({
            key: messageKey,
            severity: severity || 'warn',
            summary: summary || 'Uyarı',
            detail: message
        });
    }

    public clearMessages(): void
    {
        if (this.inDialog()) {
            this.$messageService.clear('messages.dialog');
        }
        this.$messageService.clear('messages');
    }

    public clearToastMessages(): void
    {
        this.$messageService.clear('notifications');
    }

    protected inDialog(): boolean
    {
        return document.getElementsByTagName('p-dynamicdialog').length > 0;
    }
}


type Severity = 'success' | 'warn' | 'error' | 'info' | undefined | null | '';
