export enum CallStatus
{
    Answered = 'answered',
    Missed = 'missed',
    Busy = 'busy',
    Timeout = 'timeout',
    Failed = 'failed',
    Ringing = 'ringing',
    InPhoneCall = 'in_phone_call',
    PhoneCallEnded = 'phone_call_ended',
}
