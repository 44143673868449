import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'mToKm'
})
export class MeterToKilometerPipe implements PipeTransform
{
    transform(value: number): number
    {
        if (isNaN(value)) {
            return 0;
        }
        const km = value / 1000;

        return Number(km.toFixed(2));
    }
}
