import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthModule, AuthSimpleToken} from './auth';
import {PasswordAuthProvider} from './auth/lib/strategies/password/password-provider';
import {HttpInterceptorProviders} from './interceptors';
import {LayoutModule} from './layout';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {ApiEndpointsService} from './services/api-endpoint.service';
import {ApiHttpService} from './services/api-http.service';
import {AppService} from './services/app.service';
import {PbxService} from './services/pbx.service';
import {RegionService} from './services/region.service';
import {TagColorService} from './services/tag-color.service';
import {VehicleTypeService} from './services/vehicle-type.service';

const MODULES = [
    AuthModule.forRoot({
        strategies: [
            PasswordAuthProvider.setup({
                name: 'email',
                token: {
                    class: AuthSimpleToken,
                },
                baseEndpoint: `${environment.api_endpoint}/auth/`,
                revoke: {
                    redirect: {
                        success: '/auth/login',
                        failure: '/auth/login',
                    },
                },
                requestPass: {
                    redirect: {
                        success: '/auth/reset-password',
                    },
                },
                resetPass: {
                    redirect: {
                        success: '/auth/login',
                    },
                },
                errors: {
                    key: 'message',
                },
            }),
        ]
    }),
    LayoutModule,
];

@NgModule({
    imports: [CommonModule, RouterModule, ...MODULES],
    exports: [CommonModule],
    declarations: [],
    providers: [HttpInterceptorProviders]
})
export class CoreModule
{
    constructor(@Optional() @SkipSelf() parentModule: CoreModule)
    {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule>
    {
        return {
            ngModule: CoreModule,
            providers: [
                ApiHttpService,
                ApiEndpointsService,
                AppService,
                RegionService,
                VehicleTypeService,
                TagColorService,
                PbxService,
            ],
        };
    }
}
