import {ValidationErrors, ValidatorFn, AbstractControl} from '@angular/forms';

export class PasswordValidator
{
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn
    {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // eğer kontrol boşsa hata verme
                return null;
            }

            // kontrolün değerini verilen regexp'e karşı test et
            const valid = regex.test(control.value);

            // doğruysa, hata verme (hata yok), aksi halde ikinci parametrede hata döndürülür
            return valid ? null : error;
        };
    }

    static passwordMatchValidator(control: AbstractControl): ValidationErrors | null
    {
        const password: string = control.get('password').value;
        const passwordConfirmation: string = control.get('password_confirmation').value;

        if (password !== passwordConfirmation) {
            control.get('password_confirmation').setErrors({nomismatch: true});

            return {nomismatch: true};
        }
    }
}
