import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../auth';
import {AppService} from '../services/app.service';
import {AppInjector} from './app-injector';

@Injectable({
    providedIn: 'root'
})
export class ComponentBase implements OnDestroy
{
    /**
     * Bileşen her yıkıldığında yayılır.
     *
     * @return {Subject<void>}
     */
    protected readonly destroy$ = new Subject<void>();

    protected dataLoaded$ = new Subject<boolean>();

    // public authenticatedUser: AuthUser;

    public _dataLoaded = false;

    public _hasError = false;

    constructor(public $authService?: AuthService, public $appService?: AppService)
    {
        const injector = AppInjector.getInjector();
        this.$authService = injector.get(AuthService);
        this.$appService = injector.get(AppService);

        // this.auth.getToken().pipe(takeUntil(this.destroy$)).subscribe(token => this.authenticatedUser = token.getUser());
        this.dataLoaded$.pipe(takeUntil(this.destroy$)).subscribe(state => this._dataLoaded = state);
    }

    ngOnDestroy(): void
    {
        this.destroy$.next();
        this.destroy$.complete();
        this.dataLoaded$.unsubscribe();
        this.dataLoaded$ = null;
        this._dataLoaded = null;
    }
}
