import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MeterToKilometerPipe} from './meter-to-kilometer.pipe';

@NgModule({
    imports: [CommonModule],
    exports: [MeterToKilometerPipe],
    declarations: [MeterToKilometerPipe],
    providers: [MeterToKilometerPipe]
})
export class MeterToKilometerModule
{
}
