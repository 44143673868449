import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CallDirection, CallStatus} from '../../modules/crm/lib/models';
import {DropdownListItem} from '../models';
import {ApiEndpointsService} from './api-endpoint.service';
import {ApiHttpService} from './api-http.service';

@Injectable()
export class PbxService extends ApiHttpService
{
    constructor(public http: HttpClient, public apiEndpointService: ApiEndpointsService)
    {
        super(http);
    }

    public originate(options: OriginateOptions): Observable<any>
    {
        return this.get(this.apiEndpointService.createUrl('pbx/originate'), {params: options});
    }

    public hangup(options: HangupOptions): Observable<any>
    {
        return this.get(this.apiEndpointService.createUrl('pbx/hangup'), {params: options});
    }

    public getCallIcon(callDetails: CallDetails): string
    {
        let iconName, className;

        switch (callDetails.status) {
            case CallStatus.Ringing:
                iconName = 'telephone';
                className = callDetails.direction === 'inbound_call' ? 'text-success' : 'text-primary';
                break;
            case CallStatus.InPhoneCall:
                iconName = 'volume-up';
                className = callDetails.direction === 'inbound_call' ? 'text-success' : 'text-primary';
                break;
            case CallStatus.PhoneCallEnded:
                iconName = 'telephone';
                className = callDetails.direction === 'inbound_call' ? 'text-success' : 'text-primary';
                break;
            case CallStatus.Missed:
            case CallStatus.Busy:
                iconName = 'telephone-x';
                className = 'text-danger';
                break;
            case CallStatus.Answered:
                iconName = 'telephone';
                className = 'text-success';
                break;
        }

        return `bi bi-${iconName} ${className}`;
    }

    public getCallDirections(includeAll = true): DropdownListItem[]
    {
        const items = [
            {title: 'Gelen Arama', value: 'inbound_call'},
            {title: 'Giden Arama', value: 'outbound_call'},
        ];

        if (includeAll) {
            items.push({title: 'Her ikisi de', value: ''});
        }

        return items;
    }

    public getCallStatuses(includeAll = true): DropdownListItem[]
    {
        const items = [
            {title: 'Cevaplanmış', value: 'answered'},
            {title: 'Ulaşılamadı', value: 'missed|busy|failed|timeout'},
            {title: 'Cevapsız', value: 'missed'},

        ];

        if (includeAll) {
            items.push({title: 'Tümü', value: ''});
        }

        return items;
    }
}

interface OriginateOptions
{
    internal_number: string;
    phone_number: string;
}

interface HangupOptions
{
    sys_id: string;
}


interface CallDetails
{
    status: CallStatus;
    direction: CallDirection;
}
