<div [formGroup]="form">

    <div class="row mb-3">
        <div class="col-sm-12">
            <p-messages key="messages.dialog" [escape]="false"></p-messages>
        </div>
    </div>

    <div class="row mb-3">
        <label class="col-sm-4 col-form-label">Yöntem</label>
        <div class="col-sm-8">
            <div class="p-field">
                <div class="p-field-radiobutton">
                    <p-radioButton formControlName="method" value="manual" label="Elle ayarla"></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                    <p-radioButton formControlName="method" value="auto" label="Otomatik"></p-radioButton>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="controlMethod.value == 'manual'">
        <div class="row mb-3">
            <label class="col-sm-4 col-form-label">Parola</label>
            <div class="col-sm-8">
                <input type="password" pInputText formControlName="password"/>
                <ng-container *ngIf="(controlPassword.touched || controlPassword.dirty) && controlPassword.errors">
                    <div class="invalid-message" *ngIf="controlPassword.errors.required">Parola alanı gerekli</div>
                </ng-container>
            </div>
        </div>

        <div class="row mb-3">
            <label class="col-sm-4 col-form-label">Parolayı Tekrarla</label>
            <div class="col-sm-8">
                <input type="password" pInputText formControlName="password_confirmation"/>
                <ng-container *ngIf="(controlPasswordConfirmation.touched || controlPasswordConfirmation.dirty) && controlPasswordConfirmation.errors">
                    <div class="invalid-message" *ngIf="controlPasswordConfirmation.errors.required">Lütfen parolayı tekrar girin</div>
                    <div class="invalid-message" *ngIf="controlPasswordConfirmation.errors.nomismatch">Parolalar eşleşmiyor</div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="controlMethod.value == 'auto'">
        <div class="row mb-3">
            <div class="col-sm-12">
                <p>Kullanıcının yeni parolası otomatik olarak oluşturulup SMS mesajı gönderilecek.</p>
            </div>
        </div>
    </ng-container>

</div>

<div style="display:flex;gap:15px;justify-content:end;">
    <button pButton pRipple type="button" class="p-button-outlined" label="Vazgeç" icon="pi pi-times" (click)="onCanceled()"></button>
    <button pButton pRipple type="button" [label]="controlMethod.value === 'manual' ? 'Kaydet':'Devam Et'" icon="pi pi-check" [loading]="loading" (click)="onSubmit()"></button>
</div>
