import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {GlobalHttpInterceptor} from './global-http-interceptor';
import {NoopInterceptor} from './noop-interceptor';
import {DefaultInterceptor} from './default-interceptor';
import {AuthJWTInterceptor} from '../auth';

export const HttpInterceptorProviders = [

    /** Dokunulmamış talebi sonraki istek işleyicisine iletin. */
    {provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true},

];
