import {Pipe, PipeTransform} from '@angular/core';
import {format, NumberFormat, ParsedNumber} from 'libphonenumber-js';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform
{
    transform(value: any | ParsedNumber, args: NumberFormat = 'NATIONAL'): string
    {
        if (!value) {
            return value;
        }

        return format(value, args);
    }
}
