import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AuthService} from '../auth.service';
import {AuthToken} from '../token/auth-token/auth-token';

@Injectable()
export class AuthJWTInterceptor implements HttpInterceptor
{
    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return this.authService.isAuthenticatedOrRefresh()
            .pipe(
                switchMap(authenticated => {
                    if (authenticated) {
                        return this.authService.getToken().pipe(
                            switchMap((token: AuthToken) => {

                                const headers: any = {};
                                headers['Authorization'] = `Bearer ${token.getValue()}`;

                                request = request.clone({setHeaders: headers});

                                return next.handle(request);
                            }),
                        );
                    }
                    else {
                        // Request is sent to server without authentication so that the client code
                        // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
                        return next.handle(request);
                    }
                }),
            );
    }

    protected get authService(): AuthService
    {
        return this.injector.get(AuthService);
    }
}
