import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {takeUntil} from 'rxjs/operators';
import {ComponentBase} from '../../../../../core/classes/component-base';
import {PasswordValidator} from '../../../../validators/password.validator';
import {AccountType} from '../models';
import {PasswordService} from '../services/password.service';

@Component({
    selector: 'change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordDialogComponent extends ComponentBase implements OnInit, OnDestroy
{
    loading: boolean;

    accountType: AccountType;
    accountId: number;

    public form = new FormGroup({
        method: new FormControl('manual', Validators.required),
        account_type: new FormControl('', Validators.required),
        account_id: new FormControl('', Validators.required),
        password: new FormControl('', [Validators.required, Validators.min(6)]),
        password_confirmation: new FormControl('', [Validators.required, Validators.min(6)]),
    }, {
        validators: PasswordValidator.passwordMatchValidator,
    });

    constructor(private $passwordService: PasswordService, public ref: DynamicDialogRef, public config: DynamicDialogConfig)
    {
        super();
        const data = config.data;
        this.accountType = data.account_type;
        this.accountId = data.account_id;
    }

    get controlMethod()
    {
        return this.form.get('method');
    }

    get controlAccountType()
    {
        return this.form.get('account_type');
    }

    get controlAccountId()
    {
        return this.form.get('account_id');
    }

    get controlPassword()
    {
        return this.form.get('password');
    }

    get controlPasswordConfirmation()
    {
        return this.form.get('password_confirmation');
    }

    onSubmit(): void
    {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.$appService.clearMessages();
            this.$appService.showMessage('Formda boş veya geçersiz alanlar mevcut. Kontrol ettikten sonrar tekrar deneyin.');
            return;
        }

        this.loading = true;
        this.$passwordService.update(this.form.value).pipe(takeUntil(this.destroy$)).subscribe(
            response => {
                this.$appService.handleResponseMessage(response);
                this.$appService.clearMessages();
                if (response.success) {
                    this.ref.close(this.form.value);
                }
                else {
                    this.loading = false;
                }
            },
            response => this.$appService.handleHttpErrors(response, this)
        );
    }

    onCanceled(): void
    {
        this.ref.close(false);
    }

    ngOnInit(): void
    {
        if (!this.accountType || !this.accountId) {
            this.$appService.showToastMessage('Geçersiz parametreler.', 'error', 'Oops...');
            this.ref.close(false);
            return;
        }

        this.controlAccountType.setValue(this.accountType);
        this.controlAccountId.setValue(this.accountId as any);

        this.controlMethod.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value === 'manual') {
                this.controlPassword.setValidators(Validators.required);
                this.controlPasswordConfirmation.setValidators(Validators.required);
            }
            else {
                this.controlPassword.clearValidators();
                this.controlPasswordConfirmation.clearValidators();
                this.controlPassword.setValue('');
                this.controlPasswordConfirmation.setValue('');
            }
            this.form.updateValueAndValidity();
            this.form.markAsPristine();
            this.form.markAsUntouched();
        });
    }

    ngOnDestroy(): void
    {
        super.ngOnDestroy();
    }
}
