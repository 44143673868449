import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {City} from '../interfaces';
import {ApiEndpointsService} from './api-endpoint.service';
import {ApiHttpService} from './api-http.service';

@Injectable()
export class RegionService extends ApiHttpService
{
    constructor(public http: HttpClient, public apiEndpointService: ApiEndpointsService)
    {
        super(http);
    }

    search(term: string): Observable<City[]>
    {
        return this.get(this.apiEndpointService.createUrlWithQueryParameters('search-city', (param) => {
            param.push('term', term);
        }, true));
    }
}



