import {Inject, Injectable} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {TokenService} from './token/token.service';
import {AuthToken} from './token/auth-token/auth-token';
import {AuthProvider} from '../strategies/auth-provider';
import {AUTH_PROVIDERS} from '../auth-options';
import {AuthResult} from './auth-result';

@Injectable()
export class AuthService
{
    constructor(protected tokenService: TokenService, @Inject(AUTH_PROVIDERS) protected strategies)
    {
    }

    /**
     * Depolanan mevcut kimliği doğrulanmış jetonu alın.
     *
     * @returns {Observable<any>}
     */
    getToken(): Observable<AuthToken>
    {
        return this.tokenService.get();
    }

    getUser(): UserData
    {
        const userDataRaw = localStorage.getItem('user_data');

        return JSON.parse(userDataRaw);
    }

    /**
     * Yerel depolamada kimlik doğrulama belirtecinin olup olmadığını kontrol edin.
     *
     * @returns {Observable<boolean>}
     */
    isAuthenticated(): Observable<boolean>
    {
        return this.getToken().pipe(map((token: AuthToken) => token.isValid()));
    }

    /**
     * Returns true if valid auth token is present in the token storage.
     * If not, calls the strategy refreshToken, and returns isAuthenticated() if success, false otherwise
     *
     * @returns {Observable<boolean>}
     */
    isAuthenticatedOrRefresh(): Observable<boolean>
    {
        return this.getToken()
            .pipe(
                switchMap(token => {
                    if (token.getValue() && !token.isValid()) {
                        return this.refreshToken(token.getOwnerStrategyName(), token)
                            .pipe(
                                switchMap(res => {
                                    if (res.isSuccess()) {
                                        return this.isAuthenticated();
                                    }
                                    else {
                                        return of(false);
                                    }
                                }),
                            );
                    }
                    else {
                        return of(token.isValid());
                    }
                }));
    }

    /**
     * Jeton durum akışını alın.
     *
     * @returns {Observable<AuthSimpleToken>}
     */
    onTokenChange(): Observable<AuthToken>
    {
        return this.tokenService.tokenChange();
    }

    /**
     * Kimlik doğrulama durum akışını alın.
     *
     * @returns {Observable<boolean>}
     */
    onAuthenticationChange(): Observable<boolean>
    {
        return this.onTokenChange().pipe(map((token: AuthToken) => token.isValid()));
    }

    /**
     * Seçilen strateji ile kimlik doğrulaması yapın.
     *
     * @param strategyName
     * @param data
     *
     * @returns {Observable<AuthResult>}
     */
    authenticate(data: any, strategyName: string = 'email'): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).authenticate(data)
            .pipe(
                switchMap((result: AuthResult) => {
                    return this.processResultToken(result);
                }),
            );
    }

    /**
     * Kullanıcı parolasını doğrulayın.
     *
     * @returns {Observable<AuthResult>}
     */
    validatePassword(password: string, strategyName: string = 'email'): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).validatePassword(password);
    }

    /**
     * Seçilen strateji için kayıt oluşturun.
     *
     * @param strategyName
     * @param data
     *
     * @returns {Observable<AuthResult>}
     */
    register(strategyName: string, data?: any): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).register(data)
            .pipe(
                switchMap((result: AuthResult) => {
                    return this.processResultToken(result);
                }),
            );
    }

    /**
     * Seçilen strateji ile çıkış yapın.
     * Yerel depolamadan jetonu kaldırır.
     *
     * @param strategyName
     *
     * @returns {Observable<AuthResult>}
     */
    logout(strategyName: string = 'email'): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).logout()
            .pipe(
                switchMap((result: AuthResult) => {
                    if (result.isSuccess()) {
                        this.tokenService.clear().pipe(map(() => result));
                    }
                    return of(result);
                }),
            );
    }

    /**
     * Seçili stratejiye unutulmuş şifre isteği gönderin
     *
     * Örnek:
     * requestPassword('email', {email: 'email@example.com'})
     *
     * @param strategyName
     * @param data
     *
     * @returns {Observable<AuthResult>}
     */
    requestPassword(strategyName: string, data?: any): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).requestPassword(data);
    }

    /**
     * Seçilen strateji ile şifreyi sıfırlamaya çalışın.
     *
     * Örnek:
     * resetPassword('email', {newPassword: 'test'})
     *
     * @param strategyName
     * @param data
     *
     * @returns {Observable<AuthResult>}
     */
    resetPassword(strategyName: string, data?: any): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).resetPassword(data);
    }

    /**
     * Jeton yenileme isteği gönderin
     *
     * Örnek:
     * refreshToken('email', {token: token})
     *
     * @param {string} strategyName
     * @param data
     *
     * @returns {Observable<AuthResult>}
     */
    refreshToken(strategyName: string, data?: any): Observable<AuthResult>
    {
        return this.getStrategy(strategyName).refreshToken(data)
            .pipe(
                switchMap((result: AuthResult) => {
                    return this.processResultToken(result);
                }),
            );
    }

    /**
     * Ada göre kayıtlı stratejiyi alın.
     *
     * Örnek:
     * getStrategy('email')
     *
     * @param strategyName
     *
     * @returns {AuthProvider}
     */
    protected getStrategy(strategyName: string): AuthProvider
    {
        const found = this.strategies.find((strategy: AuthProvider) => strategy.getName() === strategyName);

        if (!found) {
            throw new TypeError(`There is no Auth Strategy registered under '${strategyName}' name`);
        }

        return found;
    }

    private processResultToken(result: AuthResult)
    {
        if (result.isSuccess() && result.getToken()) {
            localStorage.setItem('user_data', result.getUser());
            return this.tokenService.set(result.getToken())
                .pipe(
                    map((token: AuthToken) => {
                        return result;
                    }),
                );
        }
        return of(result);
    }
}

interface UserData
{
    id: number;
    fullname: string;
    name: string;
    lastname: string;
    email: string;
    phone_number: string;
    image_url: string;
    role: 'admin' | 'customer_representative';
}
