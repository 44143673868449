import {Directive, EventEmitter, OnDestroy, Output} from '@angular/core';
import {
    DatatableLengthChangedEvent,
    DatatableLoadedEvent,
    DatatableOrderChangedEvent,
    DatatablePageChangedEvent,
    DatatableProcessingEvent,
    DatatableRowClickEvent,
    DatatableSelectChangedEvent
} from './datatable.model';

@Directive()
export class Events implements OnDestroy
{
    @Output() loaded = new EventEmitter<DatatableLoadedEvent>();

    @Output() draw = new EventEmitter<any>();

    @Output() searched = new EventEmitter<any>();

    @Output() pageChanged = new EventEmitter<DatatablePageChangedEvent>();

    @Output() beforeXhr = new EventEmitter<any>();

    @Output() processing = new EventEmitter<DatatableProcessingEvent>();

    @Output() orderChanged = new EventEmitter<DatatableOrderChangedEvent>();

    @Output() lengthChanged = new EventEmitter<DatatableLengthChangedEvent>();

    @Output() error = new EventEmitter<any>();

    @Output() rowClick = new EventEmitter<DatatableRowClickEvent>();

    @Output() selectChanged = new EventEmitter<any[]>();

    @Output() sourceChanged = new EventEmitter<string>();

    @Output() parametersChanged = new EventEmitter<any>();

    ngOnDestroy(): void
    {
        this.loaded.unsubscribe();
        this.draw.unsubscribe();
        this.searched.unsubscribe();
        this.pageChanged.unsubscribe();
        this.beforeXhr.unsubscribe();
        this.processing.unsubscribe();
        this.orderChanged.unsubscribe();
        this.lengthChanged.unsubscribe();
        this.error.unsubscribe();
        this.rowClick.unsubscribe();
        this.selectChanged && this.selectChanged.unsubscribe();
        this.sourceChanged.unsubscribe();
        this.parametersChanged.unsubscribe();
    }
}

