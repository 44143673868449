import {urlBase64Decode} from '../../helpers';
import {AuthToken} from './auth-token/auth-token';
import {AuthTokenClass} from './auth-token/auth-token.interface';
import {AuthEmptyTokenError} from '../../exceptions/token/auth-empty-token';
import {AuthIllegalJWTTokenError} from '../../exceptions/token/auth-illegal-jwt-token';
import {AuthTokenNotFoundError} from '../../exceptions/token/auth-token-not-found';

export interface AuthRefreshableToken
{
    getRefreshToken(): string;

    setRefreshToken(refreshToken: string);
}

export function authCreateToken<T extends AuthToken>(tokenClass: AuthTokenClass<T>, token: any, ownerStrategyName: string, createdAt?: Date)
{
    return new tokenClass(token, ownerStrategyName, createdAt);
}

export function decodeJwtPayload(payload: string): any
{
    if (payload.length === 0) {
        throw new AuthEmptyTokenError('Cannot extract from an empty payload.');
    }

    const parts = payload.split('.');

    if (parts.length !== 3) {
        throw new AuthIllegalJWTTokenError(`The payload ${payload} is not valid JWT payload and must consist of three parts.`);
    }

    let decoded;
    try {
        decoded = urlBase64Decode(parts[1]);
    }
    catch (e) {
        throw new AuthIllegalJWTTokenError(`The payload ${payload} is not valid JWT payload and cannot be parsed.`);
    }

    if (!decoded) {
        throw new AuthIllegalJWTTokenError(`The payload ${payload} is not valid JWT payload and cannot be decoded.`);
    }

    return JSON.parse(decoded);
}

export class AuthSimpleToken extends AuthToken
{
    static NAME = 'auth:simple:token';

    constructor(protected readonly token: any, protected readonly ownerStrategyName: string, protected createdAt?: Date)
    {
        super();
        try {
            this.parsePayload();
        }
        catch (err) {
            if (!(err instanceof AuthTokenNotFoundError)) {
                // jeton mevcut, ancak yasa dışı dahil olmak üzere bir sorunu var
                throw err;
            }
        }

        this.createdAt = this.prepareCreatedAt(createdAt);
    }

    protected parsePayload(): any
    {
        this.payload = null;
    }

    protected prepareCreatedAt(date: Date)
    {
        return date ? date : new Date();
    }

    /**
     * Jetonun oluşturulma tarihini alın.
     *
     * @returns {Date}
     */
    getCreatedAt(): Date
    {
        return this.createdAt;
    }

    /**
     * Jeton değerini alın.
     *
     * @returns {string}
     */
    getValue(): string
    {
        return this.token;
    }

    getOwnerStrategyName(): string
    {
        return this.ownerStrategyName;
    }

    /**
     * Boş değil ve geçerli olup olmadığını kontrol edin.
     *
     * @returns {boolean}
     */
    isValid(): boolean
    {
        return !!this.getValue();
    }

    /**
     * Değeri doğrula ve dizeye dönüştür, değer geçerli değilse boş dize alın.
     *
     * @returns {string}
     */
    toString(): string
    {
        return !!this.token ? this.token : '';
    }
}

export class AuthJWTToken extends AuthSimpleToken
{
    static NAME = 'auth:jwt:token';

    /**
     * JWT jetonu için jeton yükünün `iat` (issued at) alanı oluşturma tarihini hazırlayın
     *
     * @return {Date}
     */
    protected prepareCreatedAt(date: Date): Date
    {
        const decoded = this.getPayload();

        return decoded && decoded.iat ? new Date(Number(decoded.iat) * 1000) : super.prepareCreatedAt(date);
    }

    /**
     * Yük nesnesini alın.
     *
     * @returns any
     */
    protected parsePayload(): void
    {
        if (!this.token) {
            throw new AuthTokenNotFoundError('Jeton bulunamadı.');
        }
        this.payload = decodeJwtPayload(this.token);
    }

    /**
     * Son kullanma tarihini alın.
     *
     * @returns Date
     */
    getTokenExpDate(): Date
    {
        const decoded = this.getPayload();

        if (decoded && !decoded.hasOwnProperty('exp')) {
            return null;
        }
        const date = new Date(0);
        date.setUTCSeconds(Number(decoded.exp));

        return date;
    }

    /**
     * Verilerin süresinin dolup dolmadığını kontrol edin.
     *
     * @returns {boolean}
     */
    isValid(): boolean
    {
        return super.isValid() && (!this.getTokenExpDate() || new Date() < this.getTokenExpDate());
    }
}
