import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppService} from '../../../services/app.service';
import {AuthResult} from '../services/auth-result';
import {AuthService} from '../services/auth.service';

@Component({
    selector: 'view-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy
{
    readonly form: FormGroup = new FormGroup({
        email: new FormControl('', {validators: [Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(60)]}),
        password: new FormControl('', {validators: [Validators.required, Validators.minLength(4), Validators.maxLength(30)]})
    });

    readonly _destroy = new Subject<void>();

    loading = false;

    redirectUrl: string;

    authResult: AuthResult;

    formSubmitted: boolean;
    isError = false;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private $authService: AuthService, private $appService: AppService)
    {
        this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirect'] || '';
    }

    onSubmit(): void
    {
        this.formSubmitted = true;
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            this.$appService.showMessage('Formda boş veya geçersiz alanlar mevcut. Kontrol ettikten sonrar tekrar deneyin.');
            return;
        }

        this.loading = true;
        this.$authService.authenticate(this.form.value).pipe(takeUntil(this._destroy)).subscribe(
            authResult => {
                if (authResult.isSuccess()) {
                    this.isError = false;
                    const user = JSON.parse(authResult.getUser());

                    if (user.role === 'admin') {
                        this.router.navigateByUrl(this.redirectUrl ? decodeURI(this.redirectUrl) : '/pages/dashboard');
                    }
                    else {
                        this.router.navigateByUrl('/pages/drivers/search');
                    }
                }
                else {
                    this.loading = false;
                    this.isError = true;
                    this.authResult = authResult;
                }
            },
            response => this.$appService.handleHttpErrors(response, this)
        );
    }

    get controlEmail()
    {
        return this.form.get('email');
    }

    get controlPassword()
    {
        return this.form.get('password');
    }

    ngOnInit(): void
    {
        // this.$authService.isAuthenticated().pipe(takeUntil(this._destroy)).subscribe(authenticated => {
        //     if (authenticated) {
        //         this.loading = true;
        //         this.router.navigateByUrl(this.redirectUrl ? this.redirectUrl : '/pages/dashboard');
        //     }
        // });
    }

    ngOnDestroy(): void
    {
    }
}
