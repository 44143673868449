import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Datatable} from './datatable.component';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'datatable-search',
    template: `
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input type="search" pInputText autocomplete="off" placeholder="Arama yap" [formControl]="_searchControl" maxlength="100"/>
        </span>`,
    styleUrls: ['datatable-search.component.scss'],
    host: {
        class: 'datatable-search'
    }
})
export class DatatableSearch implements OnInit, OnDestroy
{
    /**
     * Datatable filtre formu referansı.
     */
    @Input() for: Datatable;

    /**
     * Tabloda arama yapabilmek için form kontrolü.
     *
     * @type {FormControl}
     * @private
     */
    _searchControl = new FormControl();

    readonly $destroy = new Subject();

    constructor()
    {
        // Arama girişinde bir değişiklik olduğunda
        this._searchControl.valueChanges.pipe(debounceTime(400), takeUntil(this.$destroy)).subscribe(value => this.for.search(value));
    }

    inputClear(inputRef: HTMLInputElement): void
    {
        this._searchControl.setValue('');
        inputRef.focus();
    }

    ngOnInit(): void
    {
    }

    ngOnDestroy(): void
    {
        this.$destroy.next('');
        this.$destroy.complete();
        this._searchControl = null;
        this.for = null;
    }
}
