export function convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData
{
    const formData = form || new FormData();
    // tslint:disable-next-line:prefer-const
    let formKey;

    for (const propertyName in model) {
        if (!model.hasOwnProperty(propertyName)) {
            continue;
        }


        let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

        if (model[propertyName] instanceof Date) {
            formData.append(formKey, model[propertyName].toISOString());
        }
        else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach((element, index) => {
                const tempFormKey = `${formKey}[${index}]`;
                convertModelToFormData(element, formData, tempFormKey);
            });
        }
        else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
            convertModelToFormData(model[propertyName], formData, formKey);
        }
        else if (model[propertyName] === true || model[propertyName] === false) {
            formData.append(formKey, model[propertyName] ? '1' : '0');
        }
        else {
            formData.append(formKey, model[propertyName].toString());
        }
    }

    return formData;
}
