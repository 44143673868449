import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutService
{
    private fullWidth$ = new BehaviorSubject<boolean>(false);

    public fullWidthChanged = this.fullWidth$.asObservable();

    public setFullWidth(status: boolean): void
    {
        this.fullWidth$.next(status);
    }
}
