import {Injectable} from '@angular/core';
import {CanLoad, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad
{
    constructor(private $authService: AuthService, private router: Router)
    {
    }

    canLoad(): Observable<boolean>
    {
        return this.$authService.isAuthenticated()
            .pipe(
                filter(val => val !== null),
                take(1),
                map(isAuthenticated => {
                    if (isAuthenticated) {
                        const user = this.$authService.getUser();

                        if (user.role === 'admin') {
                            this.router.navigate(['/pages/dashboard']);
                        }
                        else if (user.role === 'customer_representative') {
                            this.router.navigate(['/pages/drivers/search']);
                        }
                    }
                    else {
                        //  this.$authService.logout();

                        return true;
                    }
                })
            );
    }
}
