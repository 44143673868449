import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {LayoutService} from '../services/layout.service';

@Component({
    selector: 'view-wrapper',
    templateUrl: './page.component.html',
})
export class PageComponent implements OnDestroy
{
    /**
     * Bileşen her yıkıldığında yayılır.
     *
     * @return {Subject<void>}
     */
    protected readonly destroy$ = new Subject<void>();

    public containerFullWidth = false;

    constructor(public layoutService: LayoutService)
    {
        this.layoutService.fullWidthChanged.subscribe(status => this.containerFullWidth = status);
    }

    ngOnDestroy(): void
    {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }
}
