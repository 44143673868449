import {Inject, Injectable, InjectionToken} from '@angular/core';
import {authCreateToken} from './token';
import {AUTH_TOKENS} from '../../auth-options';
import {AuthToken} from './auth-token/auth-token';
import {AuthTokenClass} from './auth-token/auth-token.interface';

export interface TokenPack
{
    name: string;
    ownerStrategyName: string;
    createdAt: number;
    value: string;
}

export const NB_AUTH_FALLBACK_TOKEN = new InjectionToken<AuthTokenClass>('Auth Options');

/***
 * Depolanabilecek bir token paketi oluşturur.
 */
@Injectable()
export class AuthTokenParceler
{
    constructor(@Inject(NB_AUTH_FALLBACK_TOKEN) private fallbackClass: AuthTokenClass, @Inject(AUTH_TOKENS) private tokenClasses: AuthTokenClass[]) {}

    wrap(token: AuthToken): string
    {
        return JSON.stringify({
            name     : token.getName(),
            createdAt: token.getCreatedAt().getTime(),
            value    : token.toString(),
        });
    }

    unwrap(value: string): AuthToken
    {
        let tokenClass: AuthTokenClass = this.fallbackClass;
        let tokenValue = '';
        let tokenOwnerStrategyName = '';
        let tokenCreatedAt: Date = null;

        const tokenPack: TokenPack = this.parseTokenPack(value);
        if (tokenPack) {
            tokenClass = this.getClassByName(tokenPack.name) || this.fallbackClass;
            tokenValue = tokenPack.value;
            tokenOwnerStrategyName = tokenPack.ownerStrategyName;
            tokenCreatedAt = new Date(Number(tokenPack.createdAt));
        }

        return authCreateToken(tokenClass, tokenValue, tokenOwnerStrategyName, tokenCreatedAt);
    }

    protected getClassByName(name): AuthTokenClass
    {
        return this.tokenClasses.find((tokenClass: AuthTokenClass) => tokenClass.NAME === name);
    }

    protected parseTokenPack(value): TokenPack
    {
        try {
            return JSON.parse(value);
        }
        catch (e) {
        }
        return null;
    }
}
