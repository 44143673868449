import {Component} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {DatatableService} from './shared/components/datatable';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>`,
})
export class AppComponent
{
    constructor(private $datatableService: DatatableService, private $primengConfig: PrimeNGConfig)
    {
        this.$datatableService.setConfig({
            rows: 20,
            language: {
                decimal: ',',
                thousands: '.',
                emptyTable: 'Tabloda herhangi bir veri mevcut değil',
                info: '_TOTAL_ kayıt. _START_ - _END_ arası.',
                infoEmpty: 'Kayıt yok',
                infoFiltered: '(_MAX_ kayıt içerisinden bulunan)',
                infoPostFix: '',
                lengthMenu: 'Sayfada _MENU_ kayıt göster',
                loadingRecords: 'Yükleniyor...',
                processing: 'İşleniyor...',
                search: 'Ara:',
                zeroRecords: 'Eşleşen kayıt bulunamadı',
                select: {
                    rows: {
                        _: '%d öğe seçildi',
                        1: '1 öğe seçildi'
                    }
                },
                paginate: {
                    first: '',
                    last: '',
                    next: '',
                    previous: ''
                },
                aria: {
                    sortAscending: ': artan sütun sıralamasını aktifleştir',
                    sortDescending: ': azalan sütun sıralamasını aktifleştir'
                },
            }
        });

        this.$primengConfig.setTranslation({
            startsWith: 'Starts with',
            contains: 'Contains',
            notContains: 'Not contains',
            endsWith: 'Ends with',
            equals: 'Equals',
            notEquals: 'Not equals',
            noFilter: 'No Filter',
            lt: 'Less than',
            lte: 'Less than or equal to',
            gt: 'Greater than',
            gte: 'Great then or equals',
            is: 'Is',
            isNot: 'Is not',
            before: 'Before',
            after: 'After',
            clear: 'Temizle',
            apply: 'Uygula',
            matchAll: 'Match All',
            matchAny: 'Match Any',
            addRule: 'Kural Ekle',
            removeRule: 'Kuralı Kaldır',
            accept: 'Evet',
            reject: 'Hayır',
            choose: 'Seç',
            upload: 'Yükle',
            cancel: 'Vazgeç',
            dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
            dayNamesShort: ['Paz', 'Pzt', 'Sal', 'Çrş', 'Prş', 'Cum', 'Cmt'],
            dayNamesMin: ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
            monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
            monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
            today: 'Bugün',
            weekHeader: 'Wk',
            weak: 'Zayıf',
            medium: 'Orta',
            strong: 'Güçlü',
            passwordPrompt: 'Bir parola girin',
            emptyMessage: 'Sonuç bulunamadı',
            emptyFilterMessage: 'Sonuç bulunamadı',
            dateFormat: 'dd/mm/yy',
        });
    }
}
