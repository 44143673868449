import {Injectable} from '@angular/core';
import {CallStatus, PersonStatus} from '../../modules/crm/lib/models';
import {DriverStatus} from '../../modules/drivers/lib/models';
import {DriverDocumentStatus} from '../../modules/drivers/lib/models/driver-document-status';
import {RequestStatus} from '../../modules/requests/lib/models';
import {PaymentStatus} from '../interfaces';

@Injectable()
export class TagColorService
{
    public document(value: DriverDocumentStatus): string
    {
        switch (value) {
            case 'waiting_for_approval':
                return 'warning';
            case 'approved':
                return 'success';
            case 'rejected':
            case 'missing':
                return 'danger';
            default:
                return 'primary';
        }
    }

    public payment(value: PaymentStatus): string
    {
        switch (value) {
            case PaymentStatus.Pending:
                return 'warning';
            case PaymentStatus.Done:
                return 'success';
            case PaymentStatus.Canceled:
            case PaymentStatus.Rejected:
                return 'danger';
            default:
                return 'primary';
        }
    }

    public driver(value: DriverStatus): string
    {
        switch (value) {
            case DriverStatus.DocumentPending:
                return 'secondary';
            case DriverStatus.Active:
                return 'success';
            case DriverStatus.Suspended:
                return 'danger';
            default:
                return 'primary';
        }
    }

    public request(value: RequestStatus): string
    {
        switch (value) {
            case RequestStatus.Requested:
                return 'secondary';
            case RequestStatus.Expired:
                return 'warning';
            case RequestStatus.DriverCanceled:
            case RequestStatus.CustomerCanceled:
            case RequestStatus.NoCloseFound:
                return 'danger';
            case RequestStatus.Finished:
                return 'success';
            default:
                return 'primary';
        }
    }

    public callStatus(value: CallStatus): string
    {
        switch (value) {
            case CallStatus.Answered:
                return 'success';
            case CallStatus.Missed:
            case CallStatus.Busy:
            case CallStatus.Failed:
            case 'timeout':
                return 'danger';
            case CallStatus.InPhoneCall:
                return 'primary';
            case CallStatus.PhoneCallEnded:
                return 'warning';
            default:
                return 'secondary';
        }
    }

    public person(value: PersonStatus): string
    {
        switch (value) {
            case PersonStatus.PotentialCustomer:
                return 'primary';
            case PersonStatus.ActiveDriver:
                return 'success';
            case PersonStatus.Suspended:
                return 'secondary';
            case PersonStatus.Positive:
                return 'info';
            case PersonStatus.Negative:
                return 'danger';
            case PersonStatus.PendingDocuments:
                return 'warning';

            default:
                return 'primary';
        }
    }

    public customerRepresentative(value: any)
    {
        switch (value) {
            case 'active':
                return 'primary';
            case 'suspended':
                return 'danger';
            default:
                return 'primary';
        }
    }
}
