<div *ngIf="this.loading || this.initialLoading" class="datatable-loading">
    <div class="spinner-border text-secondary" role="status"></div>
    <div class="mt-4">Yükleniyor...</div>
</div>

<div class="table-wrapper">
    <table [id]="id" #tableRef class="table dataTable responsive">
        <!-- Gövde -->
        <tbody>
        <tr *ngIf="!initialLoading && !loading && !_error && items.length == 0" class="no-results">
            <td colspan="100%">Veri yok.</td>
        </tr>
        <tr *ngIf="!initialLoading && _error" class="no-results">
            <td colspan="100%">Veri alınırken bir hata oluştu.</td>
        </tr>
        <tr *ngIf="items.length == 0 && loading || initialLoading" class="no-results">
            <td colspan="100%"></td>
        </tr>
        </tbody>
    </table>
</div>
