<ngx-loading-bar color="#185ADB"></ngx-loading-bar>

<!-- Üst kısım -->
<app-header [hidden]="containerFullWidth"></app-header>

<!-- İçerik -->
<div class="page-wrapper" [ngClass]="containerFullWidth ? 'container-fluid fullwidth' : 'container'">
    <router-outlet></router-outlet>
</div>

<p-toast position="top-right" key="notifications" [baseZIndex]="10001"></p-toast>
<p-toast position="top-left" key="announcements" [baseZIndex]="10001"></p-toast>

<p-confirmDialog [style]="{width: '560px'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<!-- Alt kısım -->
<app-footer [hidden]="containerFullWidth"></app-footer>
