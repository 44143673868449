import {Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {PasswordModule} from 'primeng/password';
import {AuthRoutingModule} from './auth-routing.module';
import {AuthProvider} from './lib/strategies/auth-provider';
import {AUTH_PROVIDERS, AUTH_TOKENS, AuthProviderClass, AUTH_OPTIONS, AUTH_USER_OPTIONS, AuthOptions} from './lib/auth-options';
import {PasswordAuthProvider} from './lib/strategies/password/password-provider';
import {TokenService} from './lib/services/token/token.service';
import {AuthService} from './lib/services/auth.service';
import {AuthTokenParceler, NB_AUTH_FALLBACK_TOKEN} from './lib/services/token/token-parceler';
import {TokenLocalStorage, TokenStorage} from './lib/services/token/token-storage';
import {deepExtend} from './lib/helpers';
import {AuthProviderOptions} from './lib/strategies/auth-provider-options';
import {LoginComponent} from './lib/components/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthTokenClass} from './lib/services/token/auth-token/auth-token.interface';
import {AuthSimpleToken} from './lib/services/token/token';

export function optionsFactory(options: any)
{
    return deepExtend({}, options);
}

export function strategiesFactory(options: AuthOptions, injector: Injector): AuthProvider[]
{
    const strategies: AuthProvider[] = [];
    // @ts-ignore
    options.strategies.forEach(([strategyClass, strategyOptions]: [AuthProviderClass, AuthProviderOptions]) => {
        const strategy: AuthProvider = injector.get(strategyClass);
        strategy.setOptions(strategyOptions);

        strategies.push(strategy);
    });

    return strategies;
}

function tokensFactory(strategies: AuthProvider[]): AuthTokenClass[]
{
    const tokens: any[] = [];
    strategies.forEach((strategy: AuthProvider) => {
        tokens.push(strategy.getOption('token.class'));
    });

    return tokens;
}

const MODULES = [
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    MessagesModule,
];

const COMPONENTS = [
    LoginComponent,
];

@NgModule({
    imports: [CommonModule, ...MODULES],
    exports: [...MODULES, ...COMPONENTS],
    declarations: [...COMPONENTS],
})
export class AuthModule
{
    static forRoot(authOptions?: AuthOptions): ModuleWithProviders<AuthModule>
    {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: AUTH_USER_OPTIONS, useValue: authOptions},
                {provide: AUTH_OPTIONS, useFactory: optionsFactory, deps: [AUTH_USER_OPTIONS]},
                {provide: AUTH_PROVIDERS, useFactory: strategiesFactory, deps: [AUTH_OPTIONS, Injector]},
                {provide: AUTH_TOKENS, useFactory: tokensFactory, deps: [AUTH_PROVIDERS]},
                {provide: NB_AUTH_FALLBACK_TOKEN, useValue: AuthSimpleToken},
                {provide: TokenStorage, useClass: TokenLocalStorage},
                AuthTokenParceler,
                AuthService,
                TokenService,
                PasswordAuthProvider,
            ],
        };
    }
}
