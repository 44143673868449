export enum RequestStatus
{
    Requested = 'requested',
    DriverAccepted = 'driver_accepted',
    Started = 'started',
    Finished = 'finished',
    DriverCanceled = 'driver_canceled',
    CustomerCanceled = 'customer_canceled',
    Expired = 'expired',
    NoCloseFound = 'no_close_found',
}
